<template>
  <a
    target="_blank"
    :class="{ 'is-disabled': !selectedSiteId }"
    :href="`https://${selectedSiteSlug}.design.webflow.com/`"
    class="wf-main-button wf-open-in-webflow-button"
    @click="handleOpenInWebflowButtonClick"
    id="openInWebflowSmallButton"
  >
    <span>Continue in Webflow</span>
    <WfIcon icon="arrow-top-right" />
  </a>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { capitalizeFirstLetter } from '@/helpers/utils';

import WfIcon from '@/components/WfIcon.vue';
export default {
  components: { WfIcon },
  computed: {
    ...mapState(['userSites', 'selectedSiteId', 'selectedTab']),
    selectedSite() {
      return this.userSites.find((site) => site.id === this.selectedSiteId) || null;
    },
    selectedSiteSlug() {
      return this.selectedSite?.shortName || '';
    },
  },
  methods: {
    ...mapMutations(['setLayersSyncState', 'setVariablesSyncState', 'setStylesSyncState']),
    handleOpenInWebflowButtonClick() {
      setTimeout(() => {
        this[`set${capitalizeFirstLetter(this.selectedTab)}SyncState`]('ready');
      }, 1000);
    },
  },
};
</script>
