<template>
  <div class="wf-styles-table wf-styles-table--is-loading">
    <div class="wf-table-group">
      <div class="wf-table-row" v-for="i in rowsNumber" :key="i">
        <div class="wf-table-cell"><div class="skeleton-cell"></div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rowsNumber: 4,
    };
  },
};
</script>
