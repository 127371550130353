<template>
  <div class="wf-layer-facets">
    <WfLayerFacet v-for="facet in facets" :key="facet.id" :facet="facet" />
  </div>
</template>
<script>
import WfLayerFacet from './WfLayerFacet.vue';
export default {
  components: {
    WfLayerFacet,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    facetConfig() {
      // Facet configuration for id, icon, and the corresponding property to check
      return [
        {
          id: `${this.layerId}-components`,
          name: 'components',
          icon: 'component-facet',
          prop: 'components',
        },
        {
          id: `${this.layerId}-elements`,
          name: 'elements',
          icon: 'element-facet',
          prop: null,
          number: 1,
        }, // Always added with number 1
        {
          id: `${this.layerId}-variables`,
          name: 'variables',
          icon: 'variable-facet',
          prop: 'variables',
        },
        { id: `${this.layerId}-images`, name: 'images', icon: 'image-facet', prop: 'images' },
      ];
    },
    layerId() {
      return this.layer.layerData.id.replace(/[^a-zA-Z0-9]/g, '');
    },
    facets() {
      return this.facetConfig.reduce((acc, facet) => {
        const facetEntry = this.createFacetEntry(facet, this.layer);

        // Add only non-null entries to the final array
        if (facetEntry) {
          acc.push(facetEntry);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    // Check if a property exists and has data in the layer
    hasData(layer, prop) {
      return layer[prop] && layer[prop].length > 0;
    },
    // Check if 'elements' facet should be excluded based on layerData.type
    shouldIncludeElementsFacet(layer) {
      const excludedTypes = ['COMPONENT', 'INSTANCE'];
      return !excludedTypes.includes(layer.layerData.type);
    },
    // Create a single facet entry based on the given layer data
    createFacetEntry(facet, layer) {
      // Exclude the 'elements' facet if layerData.type is 'COMPONENT' or 'INSTANCE'
      if (facet.name === 'elements' && !this.shouldIncludeElementsFacet(layer)) {
        return null;
      }

      // If facet has no prop, it's the 'elements' case with a fixed number
      if (!facet.prop) {
        return { id: facet.id, name: facet.name, icon: facet.icon, number: facet.number };
      }

      // If the property has data, map it to the desired format
      if (this.hasData(layer, facet.prop)) {
        return {
          id: facet.id,
          name: facet.name,
          icon: facet.icon,
          number: layer[facet.prop].length,
        };
      }

      // Return null if the property does not have data
      return null;
    },
  },
};
</script>
