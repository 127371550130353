<template>
  <div class="wf-table-cell-value">
    <WfTableCellValueColor v-if="variableType === 'COLOR'" :value="variableValue" />
    <WfTableCellValueLength
      v-else-if="variableType === 'LENGTH'"
      :variable="variable"
      :value="variableValue"
    />
    <WfTableCellValueTypography v-else-if="variableType === 'STRING'" :value="variableValue" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfTableCellValueColor from './WfTableCellValueColor.vue';
import WfTableCellValueLength from './WfTableCellValueLength.vue';
import WfTableCellValueTypography from './WfTableCellValueTypography.vue';
export default {
  components: {
    WfTableCellValueColor,
    WfTableCellValueLength,
    WfTableCellValueTypography,
  },
  props: {
    variable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['selectedMode']),
    mode() {
      return this.variable.modes.find((m) => m.modeId === this.selectedMode?.id);
    },
    variableValue() {
      return this.mode?.value;
    },
    variableType() {
      return this.mode?.type;
    },
  },
};
</script>
