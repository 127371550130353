<template>
  <span class="text-ellipsis">{{ value }}</span>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
