<template>
  <div class="image-wrapper" @click.stop="zoomToImage">
    <img :src="image.url" alt="image" loading="lazy" />
  </div>
  <div class="image-name-and-export">
    <div class="image-name" :title="image.name">{{ image.name }}</div>
    <WfImageExportSettings :image="image" />
  </div>
</template>
<script>
import { dispatch } from '@/figma/ui-message-handler';
import WfImageExportSettings from './WfImageExportSettings.vue';
export default {
  components: {
    WfImageExportSettings,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  methods: {
    zoomToImage() {
      dispatch('zoomToImage', JSON.parse(JSON.stringify(this.image)));
    },
  },
};
</script>
