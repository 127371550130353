import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { __VERSION__ } from '@/helpers/version';

const isEnabled = ['production', 'staging'].includes(import.meta.env.MODE);

const datadogSettings = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'figma-to-webflow/figma-app',
  env: import.meta.env.MODE,
  version: __VERSION__,
  sessionSampleRate: 100,
};

export const setUserId = (userId) => {
  datadogLogs.setUser({ id: userId });
  datadogRum.setUser({ id: userId });
};

export const clearUserId = () => {
  datadogLogs.clearUser();
  datadogRum.clearUser();
};

export const initLogging = () => {
  datadogLogs.init({
    ...datadogSettings,
    // forwardErrorsToLogs: true,
    // forwardConsoleLogs: ['error'],
  });

  datadogRum.init({
    ...datadogSettings,
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};

export const setContext = (context) => {
  Object.entries(context).forEach(([key, value]) => {
    datadogRum.setGlobalContextProperty(key, value);
  });
};

export const defaultVueErrorHandler = (error) => {
  log(error);
};

export const log = (error, context = {}) => {
  // Log error to console in non-production environments
  if (!isEnabled) return console.trace(error);

  const { message } = error;
  context = {
    ...context,
    data: error.data,
    cause: error.cause,
  };

  // Push error to Datadog Logs and RUM
  datadogLogs.logger.error(message, context, error);
  datadogRum.addError(error, { message, context });
};
