<template>
  <div class="wf-color-swatch-wrapper" style="background-image: url('/transparency.png')">
    <div class="color-swatch" :style="{ background: getBackgroundColor }"></div>
  </div>
  <span class="text-ellipsis text-ellipsis--color">
    {{ `#${getHexFromString}` }}
  </span>
</template>
<script>
import tinycolor from 'tinycolor2';
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    getBackgroundColor() {
      const value = tinycolor(this.value).toRgb();
      const rgba = {
        r: value.r,
        g: value.g,
        b: value.b,
        a: value.a,
      };
      return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
    },
    getHexFromString() {
      return tinycolor(this.value).toHex();
    },
  },
};
</script>
