<template>
  <a
    target="_blank"
    :class="{ 'is-disabled': !selectedSiteId }"
    :href="`https://${selectedSiteSlug}.design.webflow.com/`"
    class="wf-open-webflow-in-new-tab"
    id="openInWebflowSmallButton"
  >
    <WfIcon icon="new-tab" />
  </a>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { mapState } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    this.setupTooltips();
  },
  beforeUnmount() {
    if (this.tooltip && this.tooltip[0]) {
      this.tooltip[0].destroy();
    }
  },
  computed: {
    ...mapState(['userSites', 'selectedSiteId']),
    selectedSite() {
      return this.userSites.find((site) => site.id === this.selectedSiteId) || null;
    },
    selectedSiteSlug() {
      return this.selectedSite?.shortName || '';
    },
  },
  methods: {
    setupTooltips() {
      this.tooltip = tippy('#openInWebflowSmallButton', {
        maxWidth: 200,
        theme: 'custom',
        placement: 'bottom',
        content: 'Open in Webflow',
      });
    },
  },
};
</script>
