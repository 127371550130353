<template>
  <DropdownMenu
    class="wf-selected-site-dropdown"
    direction="left"
    containerZIndex="9999"
    overlayZIndex="9998"
    :overlay="false"
    transition="none"
    :withDropdownCloser="true"
  >
    <template #trigger>
      <button class="menu">
        <span>{{ selectedHtmlTagName }}</span>
        <div class="icon-wrapper">
          <WfIcon icon="caret-down" color="#B2B2B2" />
        </div>
      </button>
    </template>
    <template #body>
      <WfSetHtmlOptions
        :layer="layer"
        :html-tag-options="availableHtmlTags"
        :selected-html-tag="layerHtmlTag"
        @set-layer-html-tag="setLayerHtmlTag"
      />
    </template>
  </DropdownMenu>
</template>
<script>
import { dispatch } from '@/figma/ui-message-handler';
import { updateLayersToSyncProperty } from '@/helpers/layers/layersHandler';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

import WfIcon from '@/components/WfIcon.vue';
import WfSetHtmlOptions from './WfSetHtmlOptions.vue';

export default {
  components: {
    DropdownMenu,

    WfIcon,
    WfSetHtmlOptions,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      layerHtmlTag: 'div',
      htmlTags: {
        text: [
          { htmlTag: 'div', name: 'Div' },
          { htmlTag: 'p', name: 'P' },
          { htmlTag: 'h1', name: 'H1' },
          { htmlTag: 'h2', name: 'H2' },
          { htmlTag: 'h3', name: 'H3' },
          { htmlTag: 'h4', name: 'H4' },
          { htmlTag: 'h5', name: 'H5' },
          { htmlTag: 'h6', name: 'H6' },
          { htmlTag: 'a', name: 'Link' },
        ],
        frame: [
          { htmlTag: 'address', name: 'Address' },
          { htmlTag: 'article', name: 'Article' },
          { htmlTag: 'aside', name: 'Aside' },
          { htmlTag: 'div', name: 'Div' },
          { htmlTag: 'figure', name: 'Figure' },
          { htmlTag: 'footer', name: 'Footer' },
          { htmlTag: 'a', name: 'Link' },
          { htmlTag: 'header', name: 'Header' },
          { htmlTag: 'main', name: 'Main' },
          { htmlTag: 'nav', name: 'Nav' },
          { htmlTag: 'section', name: 'Section' },
        ],
      },
    };
  },
  mounted() {
    this.layerHtmlTag = this.getLayerHtmlTag(this.layer);
  },
  computed: {
    availableHtmlTags() {
      return this.layer.type === 'TEXT' ? this.htmlTags.text : this.htmlTags.frame;
    },
    selectedHtmlTagName() {
      return this.availableHtmlTags.find((o) => o.htmlTag === this.layerHtmlTag)?.name || 'Div';
    },
  },
  methods: {
    setLayerHtmlTag(htmlTag) {
      this.layerHtmlTag = htmlTag.htmlTag;
      const payload = {
        id: this.layer.id,
        key: 'wfHtmlTag',
        trigger: 'htmlTagChange',
        value: this.layerHtmlTag,
      };
      updateLayersToSyncProperty(this.layer, 'wfHtmlTag', this.layerHtmlTag);
      dispatch('updateNode', payload);

      const body = { id: this.layer.id, value: `[${this.layerHtmlTag.toUpperCase()}]--` };
      dispatch('updateNodeName', body);
    },
    getLayerHtmlTag(layer) {
      return !layer.wfHtmlTag || layer.wfHtmlTag === '' || layer.wfHtmlTag === 'div'
        ? this.inferHtmlTagFromName(layer)
        : layer.wfHtmlTag;
    },
    inferHtmlTagFromName(layer) {
      if (!layer.name) return 'div';

      // Infer HTML tag from layer name
      switch (layer.name.toLowerCase()) {
        case 'description':
        case 'text':
        case 'paragraph':
          return 'p';
        case 'heading':
          return 'h1';
        case 'button':
          return 'a';
        default:
          return 'div';
      }
    },
  },
};
</script>
