<template>
  <div class="wf-settings-row-value">
    <WfDropdown
      :options="dropdownOptions"
      :selected-option="selectedOption"
      @selected="handleSelected"
      direction="right"
    >
      <span>{{ selectedOption }}</span>
      <div class="icon-wrapper">
        <WfIcon icon="caret-down" />
      </div>
    </WfDropdown>
  </div>
</template>
<script>
import { dispatch } from '@/figma/ui-message-handler';

import { mapState, mapMutations } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';
import WfDropdown from '@/components/mainContent/dropdown/WfDropdown.vue';
export default {
  components: {
    WfIcon,
    WfDropdown,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOptions: [
        { id: 'px', name: 'px' },
        { id: 'rem', name: 'rem' },
        { id: 'em', name: 'em' },
      ],
    };
  },
  computed: {
    ...mapState(['textSizeUnit', 'variableNumberUnit']),
    selectedOption() {
      return this.option.id === 'textSizeUnit' ? this.textSizeUnit : this.variableNumberUnit;
    },
  },
  methods: {
    ...mapMutations(['setTextSizeUnit', 'setVariableNumberUnit']),
    handleSelected(selectedOption) {
      dispatch('setRootPropertyValue', { prop: this.option.id, value: selectedOption.id });
      if (this.option.id === 'textSizeUnit') {
        this.setTextSizeUnit(selectedOption.id);
      } else {
        this.setVariableNumberUnit(selectedOption.id);
      }
    },
  },
};
</script>
