<template>
  <div v-memo="[memoizedStylesToSync, isLoadingStyles, stylesSyncState]" class="wf-styles-page">
    <WfStylesTableLoading v-if="isLoadingStyles" />
    <template v-else>
      <WfStylesTableEmpty v-if="isEmptyStyles" />
      <template v-else>
        <WfStylesTable v-if="stylesSyncState !== 'success'" :styles="stylesToSync" />
        <WfStylesSuccessMessage v-else />
      </template>
    </template>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import { populateSelectedStyles, populateSelectedStyleGroups } from '@/helpers/styles';

import WfStylesTable from './WfStylesTable.vue';
import WfStylesTableEmpty from './stylesTable/WfStylesTableEmpty.vue';
import WfStylesTableLoading from './stylesTable/WfStylesTableLoading.vue';
import WfStylesSuccessMessage from './stylesTable/WfStylesSuccessMessage.vue';
export default {
  components: {
    WfStylesTable,
    WfStylesTableEmpty,
    WfStylesTableLoading,
    WfStylesSuccessMessage,
  },
  data() {
    return {
      intervalId: null,
      isLoadingStyles: true,
    };
  },
  mounted() {
    dispatch('getAllStyles');
    this.intervalId = setInterval(() => {
      dispatch('getAllStyles');
    }, 5000);

    handleEvent('setAllStyles', (value) => this.setAllStyles(value));
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState(['stylesToSync', 'stylesSyncState']),
    memoizedStylesToSync() {
      return JSON.stringify(this.stylesToSync);
    },
    isEmptyStyles() {
      const isEmptyTextStyles = this.stylesToSync.textStyleGroups.length === 0;
      const isEmptyEffectStyles = this.stylesToSync.effectStyleGroups.length === 0;

      return isEmptyTextStyles && isEmptyEffectStyles;
    },
  },
  methods: {
    ...mapMutations(['setStylesToSync']),
    setAllStyles(value) {
      if (this.isLoadingStyles) {
        this.isLoadingStyles = false;
      }
      const localStyles = value[0];
      this.setStylesToSync(localStyles);

      populateSelectedStyles(localStyles);
      populateSelectedStyleGroups(localStyles);
    },
  },
};
</script>
