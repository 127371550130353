import store from '@/store';

export const updateLayersToSyncProperty = (layerToSync, property, value) => {
  const layersToSync = store.state.layersToSync;
  const newLayersToSync = layersToSync.map((layer) => {
    if (layer.layerData.id === layerToSync.id) {
      return { ...layer, layerData: { ...layer.layerData, [property]: value } };
    }
    return layer;
  });
  store.commit('setLayersToSync', newLayersToSync);
};
