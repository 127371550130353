<template>
  <div class="wf-variable-table-header">
    <WfVariablesTableCollections :selected-collection="collection" />
    <WfVariableTableModes :modes="modes" :selected-mode="selectedMode" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

import WfVariableTableModes from './WfVariableTableModes.vue';
import WfVariablesTableCollections from './WfVariablesTableCollections.vue';
export default {
  name: 'WfVariableTableHeader',
  components: {
    WfVariableTableModes,
    WfVariablesTableCollections,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modes: this.collection.modes.map((mode, i) => ({ ...mode, isSelected: i === 0 })),
    };
  },
  mounted() {
    if (!this.selectedMode) {
      this.setModes(this.collection);
    }
  },
  watch: {
    collection(newVal, oldVal) {
      this.setModes(newVal, oldVal);
    },
  },
  computed: {
    ...mapState(['selectedMode']),
  },
  methods: {
    ...mapMutations(['setSelectedMode']),
    setModes(collection, oldCollection) {
      const mode = !this.selectedMode ? collection.modes[0] : this.selectedMode;
      const selectedMode = oldCollection?.id === collection.id ? mode : collection.modes[0];
      this.setSelectedMode(selectedMode);
      this.modes = collection.modes.map((mode, i) => ({ ...mode, isSelected: i === 0 }));
    },
  },
};
</script>
