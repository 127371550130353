<template>
  <div
    class="wf-layer-warning"
    :class="{ 'wf-layer-warning--vectors': iconName === 'warning-black' }"
  >
    <WfIcon :icon="iconName" />
    <span v-text="message"></span>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  props: {
    iconName: {
      type: String,
      default: 'warning',
    },
    message: {
      type: String,
      default: 'Use auto layout to sync or copy',
    },
  },
  components: {
    WfIcon,
  },
};
</script>
