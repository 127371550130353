<template>
  <div class="number-value">
    <span class="text-ellipsis">{{ updatedValue }}</span>
    <WfDropdown
      :class="{ 'is-disabled': !this.variable.local }"
      :options="unitOptions"
      :selected-option="selectedUnit"
      @selected="handleSelected"
      direction="right"
    >
      <span>{{ selectedUnit }}</span>
      <div class="icon-wrapper">
        <WfIcon icon="caret-down" />
      </div>
    </WfDropdown>
  </div>
</template>
<script>
import { dispatch } from '@/figma/ui-message-handler';
import { updateVariableUnitInSelectedVariablesMap } from '@/helpers/variables';
import { mapState } from 'vuex';

import WfIcon from '@/components/WfIcon.vue';
import WfDropdown from '@/components/mainContent/dropdown/WfDropdown.vue';
export default {
  components: {
    WfIcon,
    WfDropdown,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    variable: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      unitOptions: [
        { id: 'px', name: 'px' },
        { id: 'em', name: 'em' },
        { id: 'rem', name: 'rem' },
      ],
      selectedUnit: this.variable.wfVariableUnit,
      updatedValue: this.value.value,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.updatedValue = newValue.value;
      },
      deep: true,
    },
    variable: {
      handler(newValue) {
        this.selectedUnit = newValue.wfVariableUnit;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['remBaseValue', 'selectedMode']),
  },
  methods: {
    handleSelected(option) {
      this.selectedUnit = option.name;
      dispatch('updateWebflowVariableUnit', {
        variableId: this.variable.id,
        unit: option.name,
      });
      this.updatedValue = updateVariableUnitInSelectedVariablesMap(
        this.variable.id,
        option.name,
        this.remBaseValue,
        this.selectedMode
      );
    },
  },
};
</script>
