<template>
  <header class="wf-selected-site">
    <WfSites />
    <WfOpenWebflowInNewTab />
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';

import WfSites from './WfSites.vue';
import WfOpenWebflowInNewTab from './WfOpenWebflowInNewTab.vue';

export default {
  components: {
    WfSites,
    WfOpenWebflowInNewTab,
  },
  mounted() {
    const defaultSiteId = 'null'; // must be a string
    dispatch('getRootPropertyValue', { prop: 'selectedSiteId', value: defaultSiteId });
    handleEvent('selectedSiteId', (siteId) => {
      if (this.userSites == null) {
        this.setSelectedSiteId(null);
        return;
      }
      // Check if the selected site is authorized
      const isAuthorizedSite = this.userSites.some((site) => site.id === siteId);
      // If it's not, make it null
      const selectedSiteId = !isAuthorizedSite ? null : siteId;
      // If it's authorized, use it.
      this.setSelectedSiteId(selectedSiteId);
    });
  },
  computed: {
    ...mapState(['userSites']),
  },
  methods: {
    ...mapMutations(['setSelectedSiteId']),
  },
};
</script>
