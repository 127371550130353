import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store';
import { dispatch } from '@/figma/ui-message-handler';

// Create a separate axios instance for refresh token requests
const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

const refreshAuthToken = async () => {
  const { refreshToken } = store.state;
  if (!refreshToken) {
    throw new Error('Unauthorized');
  }

  const response = await instance.get('/refresh-token', {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  const { token: newToken, refreshToken: newRefreshToken } = response.data;
  store.commit('setToken', newToken);
  store.commit('setRefreshToken', newRefreshToken);
  dispatch('setClientStorage', { name: 'token', data: response.data });

  return newToken;
};

const getTokenPayload = (token) => {
  if (!token || typeof token !== 'string') return null;

  try {
    const parts = token.split('.');
    if (parts.length !== 3) return null;

    return JSON.parse(atob(parts[1]));
  } catch (error) {
    return null;
  }
};

export const requestInterceptor = async (config: AxiosRequestConfig) => {
  if (!config.headers) return config;

  const bearerToken = config.headers.Authorization?.split(' ')[1];
  const payload = getTokenPayload(bearerToken);

  if (payload) {
    const isTokenExpired = payload.exp * 1000 < Date.now();

    if (isTokenExpired) {
      const newToken = await refreshAuthToken();
      config.headers.Authorization = `Bearer ${newToken}`;
    }
  }

  return config;
};

export const errorResponseInterceptor = async (error) => {
  const originalRequest = error.config;

  if (
    [401, 403].includes(error.response?.status) &&
    originalRequest.headers.Authorization &&
    !originalRequest._retry
  ) {
    const newToken = await refreshAuthToken();
    originalRequest._retry = true;
    originalRequest.headers.Authorization = `Bearer ${newToken}`;
    return axios(originalRequest);
  }

  throw error;
};
