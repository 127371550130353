<template>
  <div class="wf-image-export-settings">
    <template v-if="image.group === 'exportsGroup'">
      <span>{{ image.type.toUpperCase() }}</span>
      <span v-if="image.type !== 'svg'">{{ image.scale }}x</span>
    </template>
    <WfImageExportSettingsExtensionDropdown v-else :image="image" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import WfImageExportSettingsExtensionDropdown from './WfImageExportSettingsExtensionDropdown.vue';

export default {
  components: {
    WfImageExportSettingsExtensionDropdown,
  },
  data() {
    return {
      isExtOpen: false,
      isScaleOpen: false,
    };
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['availableExtensions', 'availableScales']),
  },
  methods: {
    ...mapMutations(['setNewPropValueImage']),
    updateSelection(id, prop, value) {
      this.setNewPropValueImage({ id, prop, value });
      if (prop === 'ext') {
        this.isExtOpen = false;
      } else if (prop === 'scale') {
        this.isScaleOpen = false;
      }
    },
  },
};
</script>
