<template>
  <div class="wf-page-content" :class="{ 'is-empty-table': isEmpty }">
    <WfIcon icon="empty-variables" />
    <p>
      <strong>No variables</strong>
      Create a local variable or install a library with variables to get started.
    </p>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
