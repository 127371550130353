export const sliceString = (str) => {
  const chunks = [];
  const chunkSize = 16384;
  const stringSize = new Blob([str]).size;
  const chunksCount = Math.ceil(stringSize / chunkSize);
  for (let i = 0, o = 0; i < chunksCount; ++i, o += chunkSize) {
    chunks.push([str.substr(o, chunkSize), i]);
  }
  return chunks;
};

export const logTime = (startTime, length, description) => {
  const endTime = performance.now();

  // Calculate the elapsed time in seconds
  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
  // Round the elapsed time to two decimal places
  const roundedElapsedTimeInSeconds = Math.round(elapsedTimeInSeconds * 100) / 100;

  // Log the elapsed time (in seconds)
  console.log(
    description + ' Took ' + roundedElapsedTimeInSeconds + ' seconds for ' + length + ' components'
  );
};

export const getSyncDuration = (startTime) => {
  const endTime = performance.now();
  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
  return Math.round(elapsedTimeInSeconds * 100) / 100;
};

export const triggerCopyToClipboard = () => {
  if (document && document.body) {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = '';
    input.focus();
    input.select();
    const success = document.execCommand('Copy');
    input.remove();
    return success;
  }
};
