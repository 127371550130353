<template>
  <DropdownMenu
    class="wf-dropdown"
    :direction="direction"
    :overlay="false"
    :withDropdownCloser="true"
    transition="none"
    :is-open="isActive"
    @opened="handleOpen"
    @closed="handleClose"
  >
    <template #trigger>
      <div class="wf-dropdown-wrapper" :class="{ 'wf-dropdown-wrapper--active': isActive }">
        <slot></slot>
      </div>
    </template>
    <template #body>
      <div class="dropdown-menu">
        <WfDropdownBodySingleOptions
          v-if="!hasTitleOptions"
          :options="options"
          :selected-option="selectedOption"
          @selected="handleSelected"
          @dropdown-closer="handleClose"
        />
        <WfDropdownBodyTitleOptions
          v-else
          :options="options"
          :selected-option="selectedOption"
          @selected="handleSelected"
        />
      </div>
    </template>
  </DropdownMenu>
</template>
<script>
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

import WfDropdownBodySingleOptions from './WfDropdownBodySingleOptions.vue';
import WfDropdownBodyTitleOptions from './WfDropdownBodyTitleOptions.vue';
export default {
  components: {
    DropdownMenu,
    WfDropdownBodyTitleOptions,
    WfDropdownBodySingleOptions,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [String, null],
      default: null,
      required: true,
    },
    direction: {
      type: String,
      default: 'left',
    },
    hasTitleOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    handleOpen() {
      this.isActive = true;
    },
    handleClose() {
      this.isActive = false;
    },
    handleSelected(option) {
      this.$emit('selected', option);
      this.isActive = false;
    },
  },
};
</script>
