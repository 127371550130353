<template>
  <div class="wf-styles-table">
    <WfRootStylesGroupRow
      v-for="rootStyle in rootStyles"
      :key="rootStyle.id"
      :groups="styles[rootStyle.array]"
      :group-id="rootStyle.id"
      :name="rootStyle.name"
    />
  </div>
</template>
<script>
import WfRootStylesGroupRow from './stylesTable/WfRootStylesGroupRow.vue';
export default {
  components: {
    WfRootStylesGroupRow,
  },
  props: {
    styles: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rootStyles: [
        { id: 'textStyles', name: 'Text styles', array: 'textStyleGroups' },
        { id: 'effectStyles', name: 'Effect styles', array: 'effectStyleGroups' },
      ],
    };
  },
};
</script>
