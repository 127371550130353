<template>
  <div v-if="images.length" class="wf-images-table">
    <WfImagesTableGroup
      v-if="exportsGroup.length"
      :group-images="exportsGroup"
      name="All"
      id="exportsGroup"
    />
  </div>
  <WfImagesTableEmpty v-else />
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import WfImagesTableEmpty from './WfImagesTableEmpty.vue';
import WfImagesTableGroup from './imagesTable/WfImagesTableGroup.vue';
export default {
  components: {
    WfImagesTableEmpty,
    WfImagesTableGroup,
  },
  data() {
    return {
      imagesOptions: ['exportsGroup', 'layersGroup'],
    };
  },
  computed: {
    ...mapState(['images']),
    ...mapGetters(['exportsGroup']),
    isSelected() {
      return this.images.every((image) => image.isSelected);
    },
    isMixed() {
      return this.images.some((image) => image.isSelected);
    },
  },
};
</script>
