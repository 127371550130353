<template>
  <div
    class="wf-table-cell"
    :class="{ 'wf-table-cell--not-root': type === 'name' && !isRoot }"
    @click="handleCheckboxChange"
  >
    <template v-if="type === 'name'">
      <WfCheckbox :status="isSelected ? 'selected' : 'unselected'" />
      <WfVariableTableCellName :variable="variable" />
    </template>
    <WfVariableTableCellValue v-else-if="type === 'value'" :variable="variable" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { toggleSelectedItem } from '@/helpers/variables';

import WfCheckbox from '../../WfCheckbox.vue';
import WfVariableTableCellName from './WfVariableTableCellName.vue';
import WfVariableTableCellValue from './WfVariableTableCellValue.vue';
export default {
  components: {
    WfCheckbox,
    WfVariableTableCellName,
    WfVariableTableCellValue,
  },
  props: {
    variable: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['selectedVariablesMap', 'selectedMode']),
    isSelected() {
      return this.selectedVariablesMap.find((v) => v.id === this.variable.id)?.isSelected ?? true;
    },
    mode() {
      return this.variable.modes.find((m) => m.modeId === this.selectedMode?.id);
    },
    variableValue() {
      return this.mode?.value;
    },
    variableType() {
      return this.mode?.type;
    },
  },
  methods: {
    handleCheckboxChange() {
      if (this.type !== 'name') {
        return;
      }
      toggleSelectedItem(this.variable.id, 'variable');
    },
  },
};
</script>
