import axios from 'axios';
import { getAuthHeader } from './headers';
import {
  requestInterceptor,
  errorResponseInterceptor,
} from '@/helpers/api/refreshTokenInterceptors';

export const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.request.use((config) => requestInterceptor(config));

instance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseInterceptor(error)
);

export const sendErrorReport = (data) => {
  const payload = { error: { origin: 'figma', data } };
  return instance.post('report-error', payload, { headers: getAuthHeader() });
};
