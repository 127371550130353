<template>
  <div v-if="groups.length" class="wf-table-group">
    <div class="wf-table-row wf-table-row--group">
      <div class="wf-group-content" @click="handleSetIsSelectedGroup">
        <WfCheckbox :status="selectionStatus" />
        {{ name }}
      </div>
    </div>
    <div class="wf-table-root-group">
      <WfStyleGroupsRow v-for="group in groups" :key="group.id" :group="group" :depth="1" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfStyleGroupsRow from './WfStyleGroupsRow.vue';
import WfCheckbox from '@/components/mainContent/WfCheckbox.vue';
import { toggleRootGroup, getRootGroupSelectionStatus } from '@/helpers/styles';
export default {
  components: {
    WfCheckbox,
    WfStyleGroupsRow,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['selectedStylesGroups', 'stylesToSync']),
    children() {
      return this.groupId === 'textStyles'
        ? this.stylesToSync.textStyleGroups
        : this.stylesToSync.effectStyleGroups;
    },
    selectionStatus() {
      return getRootGroupSelectionStatus(this.children);
    },
  },
  methods: {
    handleSetIsSelectedGroup() {
      // On click, we want to grab all children groups and variables
      // from this root group and toggle their selection status
      this.toggleAllRootChildrenGroupsRecursively(
        this.children,
        this.selectionStatus === 'selected' ? false : true
      );
    },
    toggleAllRootChildrenGroupsRecursively(children, isSelected) {
      for (const child of children) {
        toggleRootGroup(child.id, isSelected);
        if (child.styleGroups) {
          this.toggleAllRootChildrenGroupsRecursively(child.styleGroups, isSelected);
        }
      }
    },
  },
};
</script>
