<template>
  <div class="wf-table-cell wf-table-cell--header">
    <div class="wf-checkbox-wrapper" @click="handleSelectAllClick">
      <WfCheckbox :status="selectionStatus" />
    </div>
    <WfDropdown
      :options="collections"
      :selected-option="selectedCollection.id"
      @selected="setCollection"
      :has-title-options="true"
      direction="left"
    >
      <span class="text-ellipsis">{{ selectedCollection?.name }}</span>
      <div class="icon-wrapper">
        <WfIcon icon="chevron-down" />
      </div>
    </WfDropdown>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import {
  getSelectedCollectionDetails,
  getAllVariablesStatus,
  selectOrDeselectAllVariables,
} from '@/helpers/variables';

import WfIcon from '@/components/WfIcon.vue';
import WfDropdown from '@/components/mainContent/dropdown/WfDropdown.vue';
import WfCheckbox from '@/components/mainContent/WfCheckbox.vue';

export default {
  components: {
    WfIcon,
    WfDropdown,
    WfCheckbox,
  },
  props: {
    selectedCollection: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['variableLibraries', 'selectedCollectionDetails', 'selectedVariablesMap']),
    collections() {
      return this.variableLibraries.map((lib) => {
        return {
          id: lib.id,
          name: lib.name,
          isLocal: lib.local,
          variableCollections: lib.collections,
        };
      });
    },
    selectionStatus() {
      return getAllVariablesStatus(this.selectedVariablesMap); // 'selected' | 'unselected' | 'mixed'
    },
  },
  methods: {
    ...mapMutations(['setSelectedCollectionDetails', 'setIsLoadingVariablesCollection']),
    async setCollection(collection) {
      const collectionDetails = getSelectedCollectionDetails(collection);
      this.setSelectedCollectionDetails(collectionDetails);
      this.getCollectionFromFigma();
      await new Promise((resolve) => handleEvent('setCollectionById', (value) => resolve(value)));
      this.setIsLoadingVariablesCollection(false);
    },
    getCollectionFromFigma() {
      this.setIsLoadingVariablesCollection(true);
      dispatch('getCollectionById', JSON.stringify(this.selectedCollectionDetails));
    },
    handleSelectAllClick() {
      const goal = this.selectionStatus === 'selected' ? 'unselect' : 'select';
      selectOrDeselectAllVariables(goal);
    },
  },
};
</script>
