<template>
  <button
    v-if="stylesSyncState !== 'success'"
    class="wf-main-button"
    @click="handleStylesButtonClick"
    :disabled="!selectedStyles.length || isLoading"
  >
    <span
      v-if="!isLoading"
      v-text="
        selectedStyles.length
          ? `Sync ${selectedStyles.length} styles to Webflow`
          : 'Sync to Webflow'
      "
    ></span>
    <template v-else>
      <span class="small-loader"></span>
      <span>Syncing styles...</span>
    </template>
  </button>
  <template v-else>
    <button class="wf-main-button wf-main-button--secondary" @click="handleBackClick">
      <WfIcon icon="back-arrow" />
      Back
    </button>
    <WfOpenInWebflowButton />
  </template>
</template>
<script>
import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { createExportEventObject } from '@/helpers/analytics';
import { trackEvent, FIGMA_EXPORT_COMPLETED } from '@/helpers/analytics';

import WfIcon from '@/components/WfIcon.vue';
import WfOpenInWebflowButton from '@/components/mainContent/WfOpenInWebflowButton.vue';
export default {
  components: {
    WfIcon,
    WfOpenInWebflowButton,
  },
  computed: {
    ...mapState([
      'stylesSyncState',
      'isLoading',
      'selectedSiteId',
      'figmaFileName',
      'stylesToSync',
      'selectedStylesMap',
    ]),
    ...mapGetters(['selectedStyles']),
  },
  methods: {
    ...mapMutations(['setStylesSyncState', 'setIsLoading', 'setSelectedStylesMap']),
    ...mapActions(['createSyncData']),
    async handleStylesButtonClick() {
      this.setIsLoading(true);
      const startTime = performance.now();
      const styles = this.extractSelectedStyles(this.stylesToSync, this.selectedStyles);
      const syncedData = {
        styles,
        figma_file_name: this.figmaFileName,
        source: 'styles',
      };
      let syncId;
      try {
        const { data } = await this.createSyncData({
          site_id: this.selectedSiteId,
          payload: syncedData,
        });
        syncId = data.sync_id;
      } catch (error) {
        log(error);
        errorHandling(error);
        this.setIsLoading(false);
        return;
      }

      this.setStylesSyncState('success');
      const eventObject = createExportEventObject('styles', syncedData, startTime, syncId);
      trackEvent(FIGMA_EXPORT_COMPLETED, eventObject);
      this.setIsLoading(false);
      this.setAllStylesToSelected();
    },
    extractSelectedStyles(stylesToSync, selectedStyles) {
      // Step 1: Create a Set for quick lookup of selected style IDs
      const selectedStyleIds = new Set(selectedStyles.map((style) => style.id));
      // Step 2: Define a recursive function to extract selected styles from groups
      const extractFromGroups = (groups) =>
        groups.flatMap((group) => [
          // Filter styles in each group that match the selectedStyleIds
          ...(group.styles || []).filter((style) => selectedStyleIds.has(style.id)),
          // Recursively extract styles from nested styleGroups, if they exist
          ...(group.styleGroups ? extractFromGroups(group.styleGroups) : []),
        ]);

      return extractFromGroups(stylesToSync.styleGroups || []);
    },
    handleBackClick() {
      this.setStylesSyncState('ready');
    },
    setAllStylesToSelected() {
      const selectAllStyles = this.selectedStylesMap.map((s) => ({
        ...s,
        isSelected: true,
      }));
      this.setSelectedStylesMap(selectAllStyles);
    },
  },
};
</script>
