<template>
  <div class="wf-main-content wf-main-content--no-tabs-nor-footer">
    <div class="wrapper">
      <div class="wf-page-content">
        <WfIcon icon="select-site" />
        <p>
          <strong>Select site</strong>
          To get started select a Webflow site from the dropdown in the top-left corner.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
};
</script>
