<template>
  <div class="wf-table-cell-name">
    <WfIcon :icon="iconName" />
    <span class="text-ellipsis" :title="variable.name">{{ variableName }}</span>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    variable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['selectedMode']),
    mode() {
      return this.variable.modes.find((m) => m.modeId === this.selectedMode?.id);
    },
    variableType() {
      return this.mode?.type;
    },
    iconName() {
      if (this.variableType === 'COLOR') return 'color-variable';
      if (this.variableType === 'STRING') return 'typography-variable';
      return 'number-variable';
    },
    variableName() {
      return this.variable.name.split('/').pop();
    },
  },
};
</script>
