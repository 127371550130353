<template>
  <nav class="wf-navigation-tabs">
    <div class="tabs-wrapper">
      <WfNavigationTab v-for="tab in navigationTabs" :key="tab.id" :tab="tab" />
    </div>
    <WfOptionsMenu />
  </nav>
</template>
<script>
import { mapState } from 'vuex';

import WfNavigationTab from './WfNavigationTab.vue';
import WfOptionsMenu from './WfOptionsMenu.vue';
export default {
  components: {
    WfOptionsMenu,
    WfNavigationTab,
  },
  computed: {
    ...mapState(['selectedTab', 'navigationTabs']),
  },
};
</script>
