<template>
  <div class="wf-layer-facet" :id="facetId">
    <WfIcon :icon="facet.icon" />
    {{ facet.number }}
  </div>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import WfIcon from '@/components/WfIcon.vue';
import { capitalizeFirstLetter } from '@/helpers/utils';

export default {
  components: {
    WfIcon,
  },
  props: {
    facet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    this.setupTooltips();
  },
  beforeUnmount() {
    if (this.tooltip && this.tooltip[0]) {
      this.tooltip[0].destroy();
    }
  },
  watch: {
    facet: {
      handler() {
        this.updateTooltipContent(); // Update tooltip when facet object changes
      },
      deep: true, // Allows deep watching of the facet object
    },
  },
  computed: {
    facetId() {
      return `facet-${this.facet.id}`;
    },
  },
  methods: {
    setupTooltips() {
      const tooltipText = this.getTooltipText();
      this.tooltip = tippy(`#${this.facetId}`, {
        maxWidth: 200,
        theme: 'custom',
        content: tooltipText,
      });
    },
    updateTooltipContent() {
      // Check if tooltip is already initialized
      if (this.tooltip && this.tooltip[0]) {
        const tooltipText = this.getTooltipText();
        this.tooltip[0].setContent(tooltipText);
      }
    },
    getTooltipText() {
      const facetName = capitalizeFirstLetter(this.facet.name);
      return `${this.facet.number} ${this.removePluralization(this.facet.number, facetName)}`;
    },
    removePluralization(count, word) {
      return count === 1 ? this.removeLastS(word) : word;
    },
    removeLastS(word) {
      if (word.endsWith('s')) {
        return word.slice(0, -1);
      }
      return word;
    },
  },
};
</script>
