<template>
  <div class="wf-layer-row">
    <div class="start">
      <WfLayerName
        :layer="layer"
        :is-non-auto-layout-frame="isNonAutoLayoutFrame"
        :is-image="isImage"
      />
      <WfLayerFacets v-if="!isNonAutoLayoutFrame" :layer="layer" />
    </div>
    <div class="end">
      <template v-if="!isNonAutoLayoutFrame">
        <WfLayerActions :layer="layer" :is-image="isImage" />
        <WfLayerCopy :layer="layer.layerData" />
      </template>
      <WfLayerWarning v-else />
    </div>
  </div>
</template>
<script>
import WfLayerCopy from './WfLayerCopy.vue';
import WfLayerName from './layerName/WfLayerName.vue';
import WfLayerFacets from './layerFacets/WfLayerFacets.vue';
import WfLayerActions from './layerActions/WfLayerActions.vue';
import WfLayerWarning from './WfLayerWarning.vue';
export default {
  components: {
    WfLayerCopy,
    WfLayerName,
    WfLayerFacets,
    WfLayerActions,
    WfLayerWarning,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasNoChildren() {
      return this.layer.layerData.childrenCount === 0;
    },
    hasOneFill() {
      return this.layer.layerData.fillsCount === 1;
    },
    isImage() {
      return this.layer.layerData.type === 'IMAGE' || (this.hasOneFill && this.hasNoChildren);
    },
    isNonAutoLayoutFrame() {
      return (
        (this.layer.layerData.originalType === 'FRAME' && !this.layer.layerData.layoutMode) ||
        this.layer.layerData.originalType === 'GROUP'
      );
    },
  },
};
</script>
