<template>
  <div class="wf-images-table wf-images-table--is-loading">
    <div class="wf-select-all-images">
      <div class="skeleton-cell"></div>
    </div>
    <div class="wf-images-table-group">
      <div class="wf-images-table-group-select-all">
        <div class="skeleton-cell"></div>
      </div>
      <div class="wf-images-table-row" v-for="i in rowsNumber" :key="i">
        <div class="start">
          <div class="image-wrapper">
            <div class="skeleton-cell"></div>
          </div>
          <div class="image-name-and-export">
            <div class="skeleton-cell"></div>
            <div class="skeleton-cell"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rowsNumber: 3,
    };
  },
};
</script>
