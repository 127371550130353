<template>
  <svg
    width="313"
    height="257"
    viewBox="0 0 313 257"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="313" height="256.714" fill="white" />
    <rect width="313" height="256.714" fill="#FBFBFB" />
    <g clip-path="url(#clip0_8_281)">
      <path
        d="M103.431 118.641C103.431 122.524 100.283 125.672 96.4 125.672H89.1865V111.61H96.4C100.283 111.61 103.431 114.758 103.431 118.641Z"
        stroke="black"
        stroke-width="2.75408"
      />
      <path
        d="M74.9425 118.641C74.9425 122.524 78.0903 125.672 81.9733 125.672H89.1868V111.61H81.9733C78.0903 111.61 74.9425 114.758 74.9425 118.641Z"
        stroke="black"
        stroke-width="2.75408"
      />
      <path
        d="M74.9425 132.703C74.9425 136.586 78.0903 139.734 81.9733 139.734H89.1868V125.672H81.9733C78.0903 125.672 74.9425 128.82 74.9425 132.703Z"
        stroke="black"
        stroke-width="2.75408"
      />
      <path
        d="M74.9425 146.764C74.9425 150.647 78.1357 153.795 82.0187 153.795C85.9522 153.795 89.1868 150.606 89.1868 146.673V139.733H81.9733C78.0903 139.733 74.9425 142.881 74.9425 146.764Z"
        stroke="black"
        stroke-width="2.75408"
      />
      <path
        d="M89.1865 132.703C89.1865 136.586 92.3343 139.734 96.2174 139.734H96.4C100.283 139.734 103.431 136.586 103.431 132.703C103.431 128.82 100.283 125.672 96.4 125.672H96.2174C92.3343 125.672 89.1865 128.82 89.1865 132.703Z"
        stroke="black"
        stroke-width="2.75408"
      />
    </g>
    <rect
      x="50.75"
      y="92.75"
      width="78.5"
      height="78.5"
      rx="8.13889"
      stroke="#BBBBBB"
      stroke-width="1.5"
    />
    <g clip-path="url(#clip1_8_281)">
      <path
        d="M146 142L156.13 132.232L146 122.464"
        stroke="#DB4B68"
        stroke-width="3.25"
        stroke-linecap="square"
      />
      <path
        d="M167.329 122L157.199 131.768L167.329 141.536"
        stroke="#DB4B68"
        stroke-width="3.25"
        stroke-linecap="square"
      />
    </g>
    <path
      d="M235.282 125.472C235.282 125.472 231.859 136.332 231.58 137.228C231.479 136.306 228.968 116.816 228.968 116.816C223.136 116.816 220.016 121.016 218.368 125.447C218.368 125.447 214.209 136.332 213.854 137.254C213.829 136.408 213.22 125.575 213.22 125.575C212.865 120.145 207.946 116.841 203.964 116.841L208.782 146.371C214.894 146.346 218.191 142.171 219.915 137.74C219.915 137.74 223.592 128.136 223.744 127.7C223.77 128.11 226.381 146.371 226.381 146.371C232.518 146.371 235.815 142.453 237.59 138.176L246.187 116.841C240.126 116.841 236.931 121.016 235.282 125.472Z"
      fill="black"
    />
    <rect
      x="184.75"
      y="92.75"
      width="78.5"
      height="78.5"
      rx="8.13889"
      stroke="#BBBBBB"
      stroke-width="1.5"
    />
    <defs>
      <clipPath id="clip0_8_281">
        <rect width="31.4105" height="45.1069" fill="white" transform="translate(73.483 110.149)" />
      </clipPath>
      <clipPath id="clip1_8_281">
        <rect width="26" height="24" fill="white" transform="translate(144 120)" />
      </clipPath>
    </defs>
  </svg>
</template>
