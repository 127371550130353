<template>
  <button
    v-if="variablesSyncState !== 'success'"
    class="wf-main-button"
    @click="handleVariablesButtonClick"
    :disabled="!selectedVariables.length || isLoading"
  >
    <span
      v-if="!isLoading"
      v-text="
        selectedVariables.length
          ? `Sync ${selectedVariables.length} variables to Webflow`
          : 'Sync to Webflow'
      "
    ></span>
    <template v-else>
      <span class="small-loader"></span>
      <span>Syncing variables...</span>
    </template>
  </button>
  <template v-else>
    <button class="wf-main-button wf-main-button--secondary" @click="handleBackClick">
      <WfIcon icon="back-arrow" />
      Back
    </button>
    <WfOpenInWebflowButton />
  </template>
</template>
<script>
import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { getSelectedVariablesPayload, selectOrDeselectAllVariables } from '@/helpers/variables';
import { createExportEventObject } from '@/helpers/analytics';
import { trackEvent, FIGMA_EXPORT_COMPLETED } from '@/helpers/analytics';

import WfIcon from '@/components/WfIcon.vue';
import WfOpenInWebflowButton from './../WfOpenInWebflowButton.vue';
export default {
  components: {
    WfIcon,
    WfOpenInWebflowButton,
  },
  computed: {
    ...mapState([
      'isLoading',
      'variablesSyncState',
      'selectedMode',
      'selectedSiteId',
      'figmaFileName',
      'selectedVariablesMap',
    ]),
    ...mapGetters(['selectedVariables']),
  },
  methods: {
    ...mapMutations(['setVariablesSyncState', 'setIsLoading', 'setSelectedVariablesMap']),
    ...mapActions(['createSyncData']),
    async handleVariablesButtonClick() {
      this.setIsLoading(true);
      const startTime = performance.now();
      const variables = getSelectedVariablesPayload(this.selectedVariables, this.selectedMode);
      let syncId;

      const syncedData = {
        variables,
        figma_file_name: this.figmaFileName,
        source: 'variables',
      };
      try {
        const { data } = await this.createSyncData({
          site_id: this.selectedSiteId,
          payload: syncedData,
        });
        syncId = data.sync_id;
      } catch (error) {
        log(error);
        errorHandling(error);
        this.setIsLoading(false);
        return;
      }

      this.setVariablesSyncState('success');
      const eventObject = createExportEventObject('variables', syncedData, startTime, syncId);
      trackEvent(FIGMA_EXPORT_COMPLETED, eventObject);
      this.setIsLoading(false);
      selectOrDeselectAllVariables('select');
    },
    handleBackClick() {
      this.setVariablesSyncState('ready');
    },
  },
};
</script>
