<template>
  <div class="wf-layer-actions">
    <WfSetHtmlDropdown v-if="showHtmlTagDropdown" :layer="layer.layerData" />
    <WfSetResponsiveDropdown v-if="showResponsiveDropdown" :layer="layer.layerData" />
    <WfLayerWarning
      v-if="showNonAutoLayoutWarning && !isOriginalImage"
      iconName="warning-black"
      :message="`${capitalizeString(layer.layerData.originalType)} will be exported as an image`"
    />
  </div>
</template>
<script>
import WfSetHtmlDropdown from './WfSetHtmlDropdown.vue';
import WfSetResponsiveDropdown from './WfSetResponsiveDropdown.vue';
import WfLayerWarning from '../WfLayerWarning.vue';

export default {
  components: {
    WfSetHtmlDropdown,
    WfSetResponsiveDropdown,
    WfLayerWarning,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    showHtmlTagDropdown() {
      return !this.isImage;
    },
    showResponsiveDropdown() {
      const hasNoChildren = this.layer.layerData.childrenCount === 0;
      if (hasNoChildren) return false;
      const isFrame = this.layer.layerData.type === 'FRAME';
      const isHorizontal = this.layer.layerData.layoutMode === 'HORIZONTAL';
      // const isWideEnough = this.layer.layerData.width >= 239;
      const isWideEnough = true;
      return isFrame && isHorizontal && isWideEnough;
    },
    isOriginalImage() {
      return (
        this.layer.layerData.originalType === 'RECTANGLE' && this.layer.layerData.type === 'IMAGE'
      );
    },
    showNonAutoLayoutWarning() {
      const vectorTypes = [
        'LINE',
        'STAR',
        'VECTOR',
        'STICKY',
        'ELLIPSE',
        'POLYGON',
        'RECTANGLE',
        'CONNECTOR',
        'CODE_BLOCK',
        'SHAPE_WITH_TEXT',
        'BOOLEAN_OPERATION',
      ];
      return vectorTypes.includes(this.layer.layerData.originalType);
    },
  },
  methods: {
    capitalizeString(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
};
</script>
