<template>
  <div class="wf-variables-table">
    <WfVariablesTableBanner
      v-if="!hasReadDesignSystemNotification"
      message="Only one collection and mode can be synced at a time."
      color="#D7A220"
    />
    <WfVariablesTableLoading v-if="isLoadingVariablesCollection" />
    <WfVariableTableHeader :collection="collection" />
    <WfVariablesEmptyLibraries v-if="isEmpty" :is-empty="true" />
    <WfVariableGroupsRow
      v-else
      v-for="group in collection.variableGroups"
      :key="group.id"
      :group="group"
      :depth="0"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfVariablesEmptyLibraries from './WfVariablesEmptyLibraries.vue';
import WfVariableGroupsRow from './variablesTable/WfVariableGroupsRow.vue';
import WfVariableTableHeader from './variablesTable/WfVariableTableHeader.vue';
import WfVariablesTableBanner from './variablesTable/WfVariablesTableBanner.vue';
import WfVariablesTableLoading from './WfVariablesTableLoading.vue';
import { hasEmptyVariables } from '@/helpers/variables';

export default {
  components: {
    WfVariableGroupsRow,
    WfVariableTableHeader,
    WfVariablesTableBanner,
    WfVariablesEmptyLibraries,
    WfVariablesTableLoading,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['hasReadDesignSystemNotification', 'isLoadingVariablesCollection']),
    isEmpty() {
      return hasEmptyVariables(this.collection.variableGroups);
    },
  },
};
</script>
