import store from '@/store';

export const FIGMA_EXPORT_COMPLETED = 'Figma Exported';
export const FIGMA_COPY_COMPLETED = 'Figma Copy';

export const ACCEPTED_TERMS = 'Accepted Terms';
export const COMPONENTS_SYNC_INITIATED = 'Components Sync Initiated';
export const COMPONENTS_SYNC_COMPLETED = 'Components Sync Completed';
export const COPY_INITIATED = 'Copy button pressed';
export const COPY_COMPLETED = 'Copy Completed';
export const OAUTH_INITIATED = 'OAuth Connect Initiate';
export const OAUTH_COMPLETED = 'Oauth Connect Success';
export const PLUGIN_LAUNCHED = 'Plugin Launched';
export const PLUGIN_PAGE_VIEWED = 'Plugin Page Viewed';
export const PLUGIN_SIGNIN = 'Plugin Signin';
export const PREBUILT_COMPONENT_ADDED = 'Prebuilt Component Added';
export const PLUGIN_ADDITIONAL_ACTION_TAKEN = 'Plugin Additional Action Taken';
export const VARIABLES_SYNC_INITIATED = 'Variables Sync Initiated';
export const VARIABLES_SYNC_COMPLETED = 'Variables Sync Completed';
export const WEBFLOW_DESIGN_SYSTEM_CONNECTED = 'Webflow Design System Connected';

export const eventsData = {
  // Figma Exported
  [FIGMA_EXPORT_COMPLETED]: ({
    source,
    componentCount,
    elementCount,
    variableCount,
    styleCount,
    imageCount,
    syncId,
    syncDuration,
  }) => ({
    source,
    componentCount,
    elementCount,
    variableCount,
    styleCount,
    imageCount,
    syncId,
    syncDuration,
    siteId: store.state.selectedSiteId,
    userId: store.state.user?.id,
    date: new Date().toISOString(),
  }),
  [FIGMA_COPY_COMPLETED]: ({ layerType, copyDuration }) => ({
    source: 'layers',
    layerType,
    copyDuration,
    siteId: store.state.selectedSiteId,
    userId: store.state.user?.id,
    date: new Date().toISOString(),
  }),

  // Authentication & User Interaction
  [PLUGIN_LAUNCHED]: () => ({
    date: new Date().toISOString(),
  }),
  [PLUGIN_SIGNIN]: () => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
  }),
  [PREBUILT_COMPONENT_ADDED]: ({ componentType }) => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    componentType,
  }),
  [OAUTH_INITIATED]: () => ({
    date: new Date().toISOString(),
  }),
  [OAUTH_COMPLETED]: () => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
  }),
  [ACCEPTED_TERMS]: () => ({
    userId: store.state.user?.id,
  }),

  // Plugin Usage
  [PLUGIN_PAGE_VIEWED]: ({ pageName }) => ({
    pageName,
    userId: store.state.user?.id,
    date: new Date().toISOString(),
  }),
  [COPY_INITIATED]: () => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    selectedSite: store.state.selectedSiteId,
  }),
  [COPY_COMPLETED]: () => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    selectedSite: store.state.selectedSiteId,
    // copyDuration,
  }),
  [WEBFLOW_DESIGN_SYSTEM_CONNECTED]: () => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    selectedSite: store.state.selectedSiteId,
  }),
  [COMPONENTS_SYNC_INITIATED]: ({ numberOfComponents, numberOfVariables }) => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    numberOfComponents,
    numberOfVariables,
  }),
  [COMPONENTS_SYNC_COMPLETED]: ({ numberOfComponents, numberOfVariables, syncDuration }) => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    numberOfComponents,
    numberOfVariables,
    syncDuration,
  }),
  [VARIABLES_SYNC_INITIATED]: ({ numberOfVariables, typeOfVariables }) => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    numberOfVariables,
    typeOfVariables: typeOfVariables || [],
  }),
  [VARIABLES_SYNC_COMPLETED]: ({ numberOfVariables, typeOfVariables, syncDuration }) => ({
    userId: store.state.user?.id,
    date: new Date().toISOString(),
    numberOfVariables,
    typeOfVariables: typeOfVariables || [],
    syncDuration,
  }),

  // User Engagement
  [PLUGIN_ADDITIONAL_ACTION_TAKEN]: ({ actionName }) => ({
    actionName,
    pageName: store.state.currentPage?.name,
    userId: store.state.user?.id,
    date: new Date().toISOString(),
  }),
};
