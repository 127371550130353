<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import md5 from 'md5';

import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';

import { dispatch, handleEvent } from '../figma/ui-message-handler';

import {
  createImagesHashMap,
  createFigmaImagesHashMap,
  syncImagesWithAPI,
  prepareS3BucketImagesUpload,
  enqueueS3BucketImagesUpload,
} from '@/models/images/syncImages';

export default {
  mounted() {
    handleEvent('getImageHash', (bytes) => this.getImageHashForSvg(bytes));
    handleEvent('storeImages', (images) => this.storeImagesFromBytes(images));
    handleEvent('uploadImages', ({ images, eventId }) => this.uploadImages(images, eventId));
    handleEvent('storeComponentsThumbnails', (thumbnails) =>
      this.storeComponentsThumbnails(thumbnails)
    );
    handleEvent('getArrayBufferFromImage', (imageUrl) => this.handleArrayBufferFromImage(imageUrl));
  },
  computed: {
    ...mapState([
      'user',
      'userInfo',
      'token',
      'selectedSiteId',
      'maxImageSize',
      'maxFiles',
      'imagesToUpload',
    ]),
  },
  methods: {
    ...mapMutations([
      'setCopyButtonLoading',
      'setHasImagesToCopy',
      'setIsLoading',
      'setImagesToUpload',
      'setLayersSyncState',
    ]),
    ...mapActions([
      'uploadImagesToS3Bucket',
      'uploadImagesToSyncAssetsAPI',
      'uploadImagesToSyncS3Bucket',
    ]),

    async handleArrayBufferFromImage(imageUrl) {
      const request = new XMLHttpRequest();
      request.open('GET', imageUrl);
      request.responseType = 'arraybuffer';
      request.setRequestHeader('Content-Type', 'application/xml');

      request.onload = async () => {
        const arrayBufferView = await new Uint8Array(request.response);
        dispatch('setArrayBufferFromImage', arrayBufferView);
      };
      request.send();
    },
    validateImages(images) {
      if (images.length < 1) {
        dispatch('imageSavedSuccessfully', []);
        return null;
      }

      if (images.length >= this.maxFiles) {
        const message = `You are copying more than ${this.maxFiles} images (including icons). Copy smaller sections, one at a time.`;
        this.setupPayloadTooLargeErrorMessage(message);
        return null;
      }

      this.setHasImagesToCopy(true);
      return images;
    },

    /**
     * Sync Assets API
     */

    handleUploadImagesError(eventId) {
      dispatch(`uploadedImages-${eventId}`, { uploadedImages: null });
    },

    async uploadImages(images, eventId) {
      if (!images || !this.validateImages(images)) return this.handleUploadImagesError(eventId);

      const { imagesMap, hasImageLargerThanMaxSize } = createImagesHashMap({
        images,
        maxImageSize: this.maxImageSize,
      });

      if (hasImageLargerThanMaxSize) return this.handleUploadImagesError(eventId);

      const { payload } = await syncImagesWithAPI({
        images,
        syncAPI: this.uploadImagesToSyncAssetsAPI,
        siteId: this.selectedSiteId,
      });

      if (!payload) return this.handleUploadImagesError(eventId);

      const uploadImagesFns = prepareS3BucketImagesUpload({
        payload,
        imagesMap,
        s3BucketAPI: this.uploadImagesToSyncS3Bucket,
      });

      const [_, error] = await enqueueS3BucketImagesUpload({
        uploadImagesFns,
        setImagesToUpload: this.setImagesToUpload,
      });

      if (error) return this.handleUploadImagesError(eventId);

      const uploadedImagesMap = createFigmaImagesHashMap({ images, imagesMap });
      const uploadedImages = Array.from(uploadedImagesMap.entries()).map(([hash, image]) => ({
        id: hash,
        ...image,
      }));

      dispatch(`uploadedImages-${eventId}`, { uploadedImages });
      this.setHasImagesToCopy(false);
    },

    /**
     * Old Images Sync API
     */

    async storeImagesFromBytes(images) {
      if (!images || !this.validateImages(images)) return;

      let hashes = [];
      let imagesNames = [];
      const payload = new FormData();
      for (const image of images) {
        if (this.isImageHashUnique(image.imageHash, hashes)) {
          const blob = new Blob([new Uint8Array(image.bytes).buffer], {
            type: image.ext === 'png' ? 'image/png' : 'image/svg+xml',
          });

          // Ref: payload.append(name, value, filename)
          payload.append(image.imageHash, blob, image.name);
          imagesNames.push(image.imageHash);
          hashes.push(image.imageHash);
        }
      }

      payload.append('names', JSON.stringify(imagesNames));
      payload.append('siteId', this.selectedSiteId);
      payload.append('write', this.token);
      let imagesUrlAndAssetId;
      try {
        imagesUrlAndAssetId = await this.uploadImagesToS3Bucket(payload);
      } catch (error) {
        log(error);
        errorHandling(error);
        dispatch('imageSavedSuccessfully', []);
        this.setIsLoading(false);
        return;
      }
      dispatch('imageSavedSuccessfully', imagesUrlAndAssetId);
      this.setHasImagesToCopy(false);
    },

    isImageHashUnique(hash, array) {
      return !array.some((item) => hash === item);
    },
    getImageHashForSvg(bytes) {
      const decode = this.decodeUint8Array(bytes);
      let svgString = decode.replace(/\n/g, '');

      // SVGs are being duplicated because the svg string is always different - specifically its IDs.
      // Solution: Parse the svg string and replace all IDs with incremental integers.
      const ids = svgString.matchAll(/id="(.*?)"/g);
      const idsArray = Array.from(ids, (x) => x[1]);
      idsArray.forEach((id, i) => (svgString = svgString.replaceAll(id, (i + 1).toString())));

      // Hash the SVG
      const hash = md5(svgString);
      dispatch('setImageHash', hash);
    },
    decodeUint8Array(bytes) {
      return new TextDecoder().decode(bytes);
    },
    areEqual(buf1, buf2) {
      if (buf1.byteLength != buf2.byteLength) return false;
      var dv1 = new Int8Array(buf1);
      var dv2 = new Int8Array(buf2);
      for (var i = 0; i != buf1.byteLength; i++) {
        if (dv1[i] != dv2[i]) return false;
      }
      return true;
    },
    setupPayloadTooLargeErrorMessage(message) {
      const error = {
        status: 413,
        message,
      };
      errorHandling(error);
      dispatch('imageSavedSuccessfully', []);
      this.setIsLoading(false);
    },
  },
};
</script>
