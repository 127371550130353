<template>
  <div class="wf-images-table-group">
    <WfImagesTableGroupSelectAll
      :name="name"
      :is-selected="isSelected"
      :is-mixed="isMixed"
      @select-all="selectAll"
    />
    <WfImagesTableRow v-for="image in groupImages" :key="image.id" :image="image" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfImagesTableRow from './WfImagesTableRow.vue';
import WfImagesTableGroupSelectAll from './WfImagesTableGroupSelectAll.vue';
export default {
  components: {
    WfImagesTableRow,
    WfImagesTableGroupSelectAll,
  },
  props: {
    groupImages: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['images']),
    isSelected() {
      return this.groupImages.every((image) => image.isSelected);
    },
    isMixed() {
      return this.groupImages.some((image) => image.isSelected);
    },
  },
  methods: {
    selectAll() {
      const selectAllFromGroup = (isSelected) => {
        this.groupImages.forEach((image) => {
          image.isSelected = isSelected;
        });
      };

      selectAllFromGroup(!this.isSelected);
    },
  },
};
</script>
