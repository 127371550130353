<template>
  <div class="dropdown-menu">
    <div
      v-for="option in htmlTagOptions"
      :key="option.htmlTag"
      class="menu-item"
      dropdown-closer
      :class="{ show: option.htmlTag === selectedHtmlTag }"
      @click="setLayerHtmlTag(option)"
    >
      <div class="icon-wrapper">
        <WfIcon icon="checkmark" color="#FFF" />
      </div>
      <span>{{ option.name }}</span>
    </div>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
    htmlTagOptions: {
      type: Array,
      required: true,
    },
    selectedHtmlTag: {
      type: String,
      required: true,
    },
  },
  methods: {
    setLayerHtmlTag(option) {
      this.$emit('set-layer-html-tag', option);
    },
  },
};
</script>
