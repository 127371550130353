<template>
  <button
    class="wf-navigation-tab"
    :class="{ 'is-selected': selectedTab === tab.id, 'is-disabled': !selectedSiteId }"
    :disabled="!selectedSiteId || isLoading"
    @click="handleSelectedTabClick(tab.id)"
  >
    <WfIcon :icon="tab.icon" :color="selectedTab === tab.id ? '#171717' : '#B3B3B3'" />
    <span>{{ tab.name }}</span>
  </button>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { capitalizeFirstLetter } from '@/helpers/utils';

import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    tab: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['selectedTab', 'hasLayersSyncDetails', 'selectedSiteId', 'isLoading']),
  },
  methods: {
    ...mapMutations([
      'setSelectedTab',
      'setLayersSyncState',
      'setVariablesSyncState',
      'setStylesSyncState',
    ]),
    handleSelectedTabClick(tabId) {
      this.setSelectedTab(tabId);
      // If the user clicks on the layers tab and doesn't have layers sync details,
      // we need to set the layers sync state to waiting
      if (tabId === 'layers' && !this.hasLayersSyncDetails) {
        this.setLayersSyncState('waiting');
      } else {
        this[`set${capitalizeFirstLetter(this.selectedTab)}SyncState`]('ready');
      }
    },
  },
};
</script>
