<template>
  <div class="wf-layers-table">
    <WfLayerRow v-for="layer in layersToSync" :key="layer.layerData.id" :layer="layer" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfLayerRow from './WfLayerRow.vue';
export default {
  components: {
    WfLayerRow,
  },
  computed: {
    ...mapState(['layersToSync']),
  },
};
</script>
