<template>
  <template v-if="imagesSyncState !== 'success'">
    <button
      class="wf-main-button"
      @click="handleImagesButtonClick"
      :disabled="!selectedImages.length || isLoading || imagesSizeExceeded"
    >
      <span
        v-if="!isLoading"
        v-text="
          selectedImages.length
            ? `Upload ${selectedImages.length} images to Webflow`
            : 'Select images to upload'
        "
      ></span>
      <template v-else>
        <span class="small-loader"></span>
        <span>Preparing images...</span>
      </template>
    </button>
    <WfWarning
      v-if="imagesSizeExceeded"
      :is-blocking="true"
      :message="`Your image${selectedImages.length === 1 ? '' : 's'} size is ${formatImageSizeToMb()}, which exceeds the max upload limit of 5MB.`"
    />
  </template>
  <template v-else>
    <button class="wf-main-button wf-main-button--secondary" @click="handleBackClick">
      <WfIcon icon="back-arrow" />
      Back
    </button>
    <WfOpenInWebflowButton />
  </template>
</template>
<script>
import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';
import { mapState, mapMutations, mapActions } from 'vuex';
import { createExportEventObject } from '@/helpers/analytics';
import { trackEvent, FIGMA_EXPORT_COMPLETED } from '@/helpers/analytics';

import WfIcon from '@/components/WfIcon.vue';
import WfWarning from '@/components/ui/WfWarning.vue';
import WfOpenInWebflowButton from '@/components/mainContent/WfOpenInWebflowButton.vue';
export default {
  components: {
    WfIcon,
    WfWarning,
    WfOpenInWebflowButton,
  },
  data() {
    return {
      selectedImagesSize: 5000,
    };
  },
  computed: {
    ...mapState([
      'images',
      'imagesSyncState',
      'maxMbImageSize',
      'figmaFileName',
      'selectedSiteId',
      'isLoading',
    ]),
    selectedImages() {
      return this.images.filter((image) => image.isSelected);
    },
    imagesSizeExceeded() {
      return this.convertKbToMb(this.selectedImagesSize) > this.maxMbImageSize;
    },
  },
  methods: {
    ...mapMutations(['setImagesSyncState', 'setIsLoading']),
    ...mapActions(['createSyncData']),
    handleImagesButtonClick() {
      switch (this.imagesSyncState) {
        case 'ready':
          this.uploadImages();
          break;
        case 'success':
          this.setImagesSyncState('ready');
          break;
      }
    },
    async uploadImages() {
      this.setIsLoading(true);

      const startTime = performance.now();
      let syncId;

      const syncedData = {
        images: this.getImagesToSync(),
        figma_file_name: this.figmaFileName,
        source: 'images',
      };

      try {
        const { data } = await this.createSyncData({
          site_id: this.selectedSiteId,
          payload: syncedData,
        });
        syncId = data.sync_id;
      } catch (error) {
        log(error);
        errorHandling(error);
        this.setIsLoading(false);
        return;
      }

      this.setImagesSyncState('success');
      const eventObject = createExportEventObject('images', syncedData, startTime, syncId);
      trackEvent(FIGMA_EXPORT_COMPLETED, eventObject);
      this.setIsLoading(false);
    },
    getImagesToSync() {
      return this.selectedImages.map((image) => ({
        id: image.id,
        hash: image.hash,
        name: image.name,
        type: image.type,
        size: image.size,
        width: image.width,
        height: image.height,
        syncType: image.syncType,
      }));
    },
    convertKbToMb(kbSize) {
      return kbSize / 1024;
    },
    formatImageSizeToMb() {
      // TODO: add logic
      const mbSize = this.convertKbToMb(this.selectedImagesSize);
      return `${mbSize.toFixed(2)}MB`;
    },
    handleBackClick() {
      this.setImagesSyncState('ready');
    },
  },
};
</script>
