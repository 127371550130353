<template>
  <div
    v-memo="[memoizedSelectedCollection, memoizedVariableLibraries, variablesSyncState]"
    class="wf-variables-page"
  >
    <WfVariablesTableLoading v-if="!selectedCollection" />
    <template v-else>
      <WfVariablesEmptyLibraries v-if="isEmptyLibraries" />
      <template v-else>
        <WfVariablesTable v-if="variablesSyncState === 'ready'" :collection="selectedCollection" />
        <WfVariablesSuccessMessage v-else-if="variablesSyncState === 'success'" />
      </template>
    </template>
  </div>
</template>
<script>
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import {
  populateSelectedVariables,
  populateSelectedVariableGroups,
  getSelectedCollectionDetails,
} from '@/helpers/variables';

import { mapState, mapGetters, mapMutations } from 'vuex';
import WfVariablesTable from './WfVariablesTable.vue';
import WfVariablesTableLoading from './WfVariablesTableLoading.vue';
import WfVariablesEmptyLibraries from './WfVariablesEmptyLibraries.vue';
import WfVariablesSuccessMessage from './WfVariablesSuccessMessage.vue';
export default {
  components: {
    WfVariablesTable,
    WfVariablesTableLoading,
    WfVariablesSuccessMessage,
    WfVariablesEmptyLibraries,
  },
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    if (!this.hasCollectionDetails) {
      dispatch('getProjectLibraries');
      handleEvent('setProjectLibraries', (value) => this.setVariablesTableData(value));
    }

    this.intervalId = setInterval(() => {
      dispatch('getCollectionById', JSON.stringify(this.selectedCollectionDetails));
    }, 5000);

    handleEvent('setCollectionById', (value) => this.setVariablesTableData(value));
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState([
      'selectedTab',
      'variablesSyncState',
      'selectedCollection',
      'selectedCollectionDetails',
      'selectedVariablesMap',
      'selectedVariableGroups',
      'variableLibraries',
    ]),
    ...mapGetters(['hasCollectionDetails']),
    memoizedSelectedCollection() {
      return JSON.stringify(this.selectedCollection);
    },
    memoizedVariableLibraries() {
      return JSON.stringify(this.variableLibraries);
    },
    isEmptyLibraries() {
      return !this.variableLibraries.length; // || !this.selectedCollection?.variableGroups.legnth;
    },
  },
  methods: {
    ...mapMutations([
      'setSelectedVariablesMap',
      'setSelectedCollection',
      'setSelectedCollectionDetails',
      'setSelectedVariableGroups',
      'setVariableLibraries',
      'setIsLoading',
    ]),
    async setVariablesTableData(response) {
      if (this.selectedTab !== 'variables') return;

      if (!response.length && !response?.variableGroups?.length) {
        this.setIsLoading(false);
        this.setVariableLibraries([]);

        this.setSelectedCollection([]);
        this.setSelectedCollectionDetails({
          collectionId: null,
          collectionKey: null,
          local: true,
        });

        this.setSelectedVariablesMap([]);
        this.setSelectedVariableGroups([]);

        return;
      }

      const selectedCollection = !this.hasCollectionDetails
        ? response[0].variableCollections[0]
        : response;

      // Get all libraries options when first time
      if (!this.hasCollectionDetails) {
        this.getLibrariesOptions(response);
      }

      // Use Vue's reactivity system to trigger updates
      await new Promise((resolve) => {
        this.$nextTick(() => {
          this.setSelectedCollection(JSON.parse(JSON.stringify(selectedCollection)));
          resolve();
        });
      });

      // We need to update when new collection is selected
      if (!this.hasCollectionDetails) {
        const collectionDetails = getSelectedCollectionDetails(this.selectedCollection);
        this.setSelectedCollectionDetails(collectionDetails);
      }

      populateSelectedVariables(selectedCollection);
      populateSelectedVariableGroups(selectedCollection);
    },
    async getLibrariesOptions(libs) {
      const libraries = this.getLibraries(libs);
      await new Promise((resolve) => {
        this.$nextTick(() => {
          this.setVariableLibraries(JSON.parse(JSON.stringify(libraries)));
          resolve();
        });
      });
    },
    getLibraries(lirabries) {
      return lirabries.map((library) => {
        return {
          id: library.id,
          name: library.name,
          local: library.local,
          collections: library.variableCollections,
        };
      });
    },
  },
};
</script>
