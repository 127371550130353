<template>
  <button
    class="wf-main-button wf-main-button--secondary wf-main-button--secondary-danger"
    :class="{ 'is-disabled': isLoading }"
    @click="handleSignOutButtonClick"
  >
    <span v-if="!isLoading">Sign out and restart</span>
    <template v-else>
      <span class="small-loader"></span>
      <span>Signing out...</span>
    </template>
  </button>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';

import { trackEvent, trackUser, PLUGIN_ADDITIONAL_ACTION_TAKEN } from '@/helpers/analytics';

export default {
  computed: {
    ...mapState(['isLoading', 'token']),
  },
  methods: {
    ...mapActions(['logUserOut']),
    ...mapMutations([
      'setIsLoading',
      'setUser',
      'setToken',
      'setRefreshToken',
      'setUserInfo',
      'setUserSites',
      'setIsLoggedIn',
      'setCurrentPage',
      'setErrorObject',
    ]),
    async handleSignOutButtonClick() {
      this.setIsLoading(true);

      const body = new FormData();
      body.append('writeKey', this.token);
      await this.logUserOut(body);
      dispatch('setClientStorage', { name: 'token', data: null });
      trackEvent(PLUGIN_ADDITIONAL_ACTION_TAKEN, { actionName: 'Logout' });
      trackUser();

      this.setUser(null);
      this.setToken(null);
      this.setRefreshToken(null);
      this.setUserInfo(null);
      this.setUserSites(null);
      this.setIsLoggedIn(false);
      this.setCurrentPage({ name: 'home', params: {} });
      this.setErrorObject({ show: false, code: null, error: null });
      this.setIsLoading(false);
    },
  },
};
</script>
