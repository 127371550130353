<template>
  <button
    v-if="errorCode === 500 || !errorCode"
    class="wf-main-button wf-main-button--secondary"
    @click="handleErrorBackButtonClick"
  >
    <WfIcon icon="back-arrow" />
    Back
  </button>
  <WfPayloadTooLargeErrorButton v-if="errorCode === 413 || errorCode === 406" />
  <WfSignOutErrorButton v-else-if="signOutErrors.includes(errorCode)" />
  <WfSendErrorReportButton v-else @handleSendErrorReportButtonClick="handleErrorBackButtonClick" />
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

import WfIcon from '@/components/WfIcon.vue';
import WfSignOutErrorButton from './WfSignOutErrorButton.vue';
import WfSendErrorReportButton from './WfSendErrorReportButton.vue';
import WfPayloadTooLargeErrorButton from './WfPayloadTooLargeErrorButton.vue';
export default {
  components: {
    WfIcon,
    WfSignOutErrorButton,
    WfSendErrorReportButton,
    WfPayloadTooLargeErrorButton,
  },
  computed: {
    ...mapGetters(['errorCode', 'error']),
    signOutErrors() {
      return [400, 401, 403, 404];
    },
  },
  methods: {
    ...mapMutations(['setErrorObject']),
    handleErrorBackButtonClick() {
      this.setErrorObject({
        show: false,
        code: null,
        error: null,
      });
    },
  },
};
</script>
