<template>
  <div class="wf-layers-page">
    <div class="wf-page-content">
      <WfIcon icon="error" />
      <p>
        <strong>{{ errorTitle }}</strong>
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  computed: { ...mapGetters(['errorTitle', 'errorMessage']) },
};
</script>
