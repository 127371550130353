<template>
  <div class="wf-images-table-row" @click="selectImage">
    <div class="start">
      <WfCheckbox :status="image.isSelected ? 'selected' : 'unselected'" />
      <WfImagesTableRowImagePreviewAndName :image="image" />
    </div>
    <WfImagesTableRowDetails :image="image" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfCheckbox from '@/components/mainContent/WfCheckbox.vue';
import WfImagesTableRowDetails from './WfImagesTableRowDetails.vue';
import WfImagesTableRowImagePreviewAndName from './WfImagesTableRowImagePreviewAndName.vue';
export default {
  components: {
    WfCheckbox,
    WfImagesTableRowDetails,
    WfImagesTableRowImagePreviewAndName,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['images']),
  },
  methods: {
    selectImage() {
      const image = this.images.find((i) => i.id === this.image.id);
      image.isSelected = !image.isSelected;
    },
  },
};
</script>
