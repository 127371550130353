<template>
  <div class="wf-variables-table-banner">
    <div class="icon-wrapper">
      <WfIcon icon="warning-outline" :color="color" />
    </div>
    <div class="message">{{ message }}</div>
    <div class="icon-wrapper" @click="closeNotification()">
      <WfIcon icon="times" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';

import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#D7A220',
    },
  },
  computed: {
    ...mapState(['hasReadDesignSystemNotification']),
  },
  methods: {
    ...mapMutations(['setHasReadDesignSystemNotification']),

    closeNotification() {
      if (!this.hasReadDesignSystemNotification) {
        this.setHasReadDesignSystemNotification(true);
        dispatch('setRootPropertyValue', {
          prop: 'hasReadDesignSystemNotification',
          value: 'true',
        });
      }
    },
  },
};
</script>
