<template>
  <button
    class="wf-button wf-button--confirmation"
    @click="confirmCopyToWebflow()"
    id="confirmCopyToWebflow"
  >
    Confirm copy
  </button>
</template>
<script>
import { mapMutations } from 'vuex';
import { triggerCopyToClipboard } from '@/helpers/helper';
import { dispatch } from '@/figma/ui-message-handler';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    this.setupTooltip();
  },
  methods: {
    ...mapMutations(['setNeedsToConfirmCopy', 'setIsLoading']),
    confirmCopyToWebflow() {
      const success = triggerCopyToClipboard();
      if (!success) {
        dispatch('notify', '❌ Number of layers is too large. Please copy smaller sections');
      }
      this.setIsLoading(false);
      this.setNeedsToConfirmCopy(false);
    },
    setupTooltip() {
      this.tooltip = tippy('#confirmCopyToWebflow', {
        maxWidth: 200,
        theme: 'custom',
        placement: 'bottom',
        content:
          'Click to confirm you want to copy to Webflow. Use the Figma Desktop App to remove this confirmation step.',
      });
    },
  },
};
</script>
