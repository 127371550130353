<template>
  <div class="wf-table-cell-name">
    <WfIcon :icon="iconName" />
    <span :title="`${styleName} · ${style.displayValue}`">
      {{ styleName }}
      <span v-if="style.displayValue" class="text-ellipsis display-value"
        >· {{ style.displayValue }}</span
      >
    </span>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    style: {
      type: Object,
      required: true,
    },
  },
  computed: {
    styleName() {
      return this.style.displayName.split('/').pop();
    },
    iconName() {
      return this.style.type === 'TEXT' ? 'text-style' : 'effect-style';
    },
  },
};
</script>
