<template>
  <div class="wf-settings-row-value">
    <div class="wf-input-wrapper">
      <input
        v-model="debounceInput"
        :type="option.id === 'remBaseValue' ? 'number' : 'text'"
        class="input-field"
        :class="{ 'input-field--number': option.id === 'remBaseValue' }"
        @blur="handleInputBlur"
        @keyup.enter="handleInputBlur"
        :placeholder="option.id === 'remBaseValue' ? '16' : 'No prefix'"
      />
      <span v-if="option.id === 'remBaseValue'">px</span>
    </div>
  </div>
</template>
<script>
import * as _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      debounceInput: '',
    };
  },
  mounted() {
    this.debounceInput = this.setDebounceInput();
  },
  watch: {
    debounceInput: _.debounce(function (inputValue) {
      this.handleInput(inputValue);
    }, 500),
  },
  computed: {
    ...mapState([
      'cssClassPrefix',
      'textStyleClassPrefix',
      'effectStyleClassPrefix',
      'remBaseValue',
    ]),
  },
  methods: {
    ...mapMutations([
      'setCssClassPrefix',
      'setTextStyleClassPrefix',
      'setEffectStyleClassPrefix',
      'setRemBaseValue',
    ]),
    setDebounceInput() {
      switch (this.option.id) {
        case 'cssClassPrefix':
          return this.cssClassPrefix === 'null' ? '' : this.cssClassPrefix;
        case 'remBaseValue':
          return this.remBaseValue === 'null' ? '16' : this.remBaseValue;
        case 'textStyleClassPrefix':
          if (this.textStyleClassPrefix === '') return 'Text';
          if (this.textStyleClassPrefix === 'null') return '';
          return this.textStyleClassPrefix;
        case 'effectStyleClassPrefix':
          if (this.effectStyleClassPrefix === '') return 'Effect';
          if (this.effectStyleClassPrefix === 'null') return '';
          return this.effectStyleClassPrefix;
        default:
          return '';
      }
    },
    handleInput(inputValue) {
      if (inputValue === '' && this.option.id === 'remBaseValue') {
        inputValue = '16';
        this.debounceInput = '16';
      }

      const value = inputValue.toString();

      if (
        value === '' &&
        (this.option.id === 'textStyleClassPrefix' || this.option.id === 'effectStyleClassPrefix')
      ) {
        const prefixValue = 'null';
        dispatch('setRootPropertyValue', { prop: this.option.id, value: prefixValue });
      } else {
        dispatch('setRootPropertyValue', { prop: this.option.id, value });
      }

      this.setValueInStore(value);
      if (value === 'null' || value === '') {
        this.debounceInput = this.option.id === 'remBaseValue' ? '16' : '';
      }
    },
    handleInputBlur() {
      if (this.debounceInput === '') {
        this.debounceInput = this.option.id === 'remBaseValue' ? '16' : '';
      }
      this.handleInput(this.debounceInput);
    },
    setValueInStore(value) {
      switch (this.option.id) {
        case 'remBaseValue':
          this.setRemBaseValue(value);
          break;
        case 'cssClassPrefix':
          this.setCssClassPrefix(value);
          break;
        case 'textStyleClassPrefix':
          this.setTextStyleClassPrefix(value === '' ? 'null' : value);
          break;
        case 'effectStyleClassPrefix':
          this.setEffectStyleClassPrefix(value === '' ? 'null' : value);
          break;
        default:
          break;
      }
    },
  },
};
</script>
