<template>
  <div class="wf-empty-images">
    <div class="wf-page-content">
      <WfIcon icon="no-variables-selected" />
      <p>
        <strong>No images</strong>
        It looks like you don't have images on this Figma file. Go ahead and add some to sync them
        to Webflow.
      </p>
    </div>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
};
</script>
