<template>
  <div class="wf-page-content">
    <WfIcon icon="success" />
    <p>
      <strong>{{ title }}</strong>
      {{ message }}
    </p>
    <a
      v-if="layersSuccessSource === 'copy'"
      href="https://university.webflow.com/lesson/figma-to-webflow-plugin#sharing-styles-between-figma-and-webflow"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn about advanced paste shortcuts
      <WfIcon icon="arrow-top-right" color="#007DF0" />
    </a>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  computed: {
    ...mapState(['layersSuccessSource']),
    title() {
      return this.layersSuccessSource === 'sync' ? 'Ready to import' : 'Copied to clipboard';
    },
    message() {
      return this.layersSuccessSource === 'sync'
        ? 'Open your site in Webflow and launch the Figma to Webflow app to import.'
        : 'Open your site in Webflow to paste.';
    },
  },
};
</script>
