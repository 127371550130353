<template>
  <div class="image-details">
    <div class="image-dimension">
      <WfIcon icon="size" color="#999" />
      <div class="end">{{ image.width }}<span>x</span>{{ image.height }}</div>
    </div>
    <div class="image-size">
      <WfIcon icon="performance" color="#999" />
      <div class="end">{{ imageSize }}</div>
    </div>
  </div>
</template>
<script>
import prettyBytes from 'pretty-bytes';
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSize() {
      return prettyBytes(this.image.size);
    },
  },
};
</script>
