<template>
  <div class="wf-page-content is-empty-table">
    <WfIcon icon="styles-large" />
    <p>
      <strong>No styles</strong>
      Create a text or effect style to get started.
    </p>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
};
</script>
