<template>
  <button
    :class="{ 'is-disabled': isLoading }"
    class="wf-main-button wf-open-in-webflow-button"
    @click="handleContinueButtonClick"
  >
    Continue
  </button>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapMutations(['setErrorObject']),
    handleContinueButtonClick() {
      this.setErrorObject({
        show: false,
        code: null,
        error: null,
      });
    },
  },
};
</script>
