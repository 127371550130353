<template>
  <div class="wf-table-group">
    <div v-if="!isRootGroup && hasVariables" class="wf-table-row wf-table-row--group">
      <div class="wf-group-content" @click="handleSetIsSelectedGroup">
        <WfCheckbox :status="selectionStatus" />
        {{ group.name }}
      </div>
    </div>
    <template v-if="hasVariables">
      <WfVariableRow
        v-for="variable in group.variables"
        :key="variable.id"
        :variable="variable"
        :is-root="isRootGroup"
      />
    </template>
    <template v-if="hasVariableGroups">
      <WfVariableGroupsRow v-for="g in group.variableGroups" :key="g.id" :group="g" />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { toggleSelectedItem, getGroupSelectionStatus } from '@/helpers/variables';

import WfVariableRow from './WfVariableRow.vue';
import WfCheckbox from '../../WfCheckbox.vue';
export default {
  name: 'WfVariableGroupsRow',
  components: {
    WfVariableRow,
    WfCheckbox,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['selectedVariableGroups']),
    isRootGroup() {
      return this.group.name === 'Root';
    },
    hasVariables() {
      return this.group.variables?.length;
    },
    hasVariableGroups() {
      return this.group.variableGroups?.length;
    },
    selectionStatus() {
      return getGroupSelectionStatus(this.group);
    },
  },
  methods: {
    handleSetIsSelectedGroup() {
      toggleSelectedItem(this.group.id, 'group');
    },
  },
};
</script>
