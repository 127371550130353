import { createStore } from 'vuex';
import { log } from '@/helpers/logger';
import * as authApi from '@/api/auth-api';
import * as syncApi from '@/api/sync-api';
import { dispatch } from '@/figma/ui-message-handler';
import { setErrorTitle, setErrorMessage } from '@/helpers/errors';
import { setContext } from '@/helpers/logger';

const preBuiltComponents = [
  { id: 'navbar-logo-left', name: 'Nav logo left', type: 'navigation', category: 'layout' },

  {
    id: 'hero-heading-left',
    name: 'Hero heading left',
    description: '',
    type: 'hero',
    category: 'layout',
  },
  {
    id: 'hero-heading-right',
    name: 'Hero heading right',
    description: '',
    type: 'hero',
    category: 'layout',
  },
  { id: 'hero-stack', name: 'Hero stack', description: '', type: 'hero', category: 'layout' },
  { id: 'hero-no-image', name: 'Hero no image', description: '', type: 'hero', category: 'layout' },

  { id: 'form-contact', name: 'Contact form', description: '', type: 'form', category: 'layout' },
  {
    id: 'form-newsletter',
    name: 'Newsletter form',
    description: '',
    type: 'form',
    category: 'layout',
  },

  { id: 'team-circles', name: 'Team Circles', description: '', type: 'team', category: 'layout' },
  {
    id: 'team-rectangles',
    name: 'Team Rectangles',
    description: '',
    type: 'team',
    category: 'layout',
  },

  {
    id: 'logos-quote-block',
    name: 'Logos Quote Block',
    description: '',
    type: 'logos',
    category: 'layout',
  },
  { id: 'logos-title', name: 'Logos Title', description: '', type: 'logos', category: 'layout' },

  {
    id: 'gallery-overview',
    name: 'Gallery Overview',
    description: '',
    type: 'gallery',
    category: 'layout',
  },
  {
    id: 'gallery-scroll',
    name: 'Gallery Scroll',
    description: '',
    type: 'gallery',
    category: 'layout',
  },

  {
    id: 'features-list',
    name: 'Features List',
    description: '',
    type: 'features',
    category: 'layout',
  },
  {
    id: 'features-metrics',
    name: 'Features Metrics',
    description: '',
    type: 'features',
    category: 'layout',
  },

  {
    id: 'pricing-comparison',
    name: 'Pricing Comparison',
    description: '',
    type: 'pricing',
    category: 'layout',
  },
  {
    id: 'pricing-items',
    name: 'Pricing Items',
    description: '',
    type: 'pricing',
    category: 'layout',
  },

  {
    id: 'testimonial-column-dark',
    name: 'Testimonial Column Dark',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },
  {
    id: 'testimonial-column-light',
    name: 'Testimonial Column Light',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },
  {
    id: 'testimonial-image-left',
    name: 'Testimonial Image Left',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },
  {
    id: 'testimonial-stack',
    name: 'Testimonial Stack',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },

  { id: 'footer-dark', name: 'Footer', description: '', type: 'footer', category: 'layout' },

  { id: 'new-page', name: 'New Page', description: '', type: 'structure', category: 'structure' },
  {
    id: 'section-container',
    name: 'Section + container',
    description: '',
    type: 'structure',
    category: 'structure',
  },
  { id: '2-col', name: '2 col', description: '', type: 'structure', category: 'structure' },
  { id: '3-col', name: '3 col', description: '', type: 'structure', category: 'structure' },
  { id: '4-col', name: '4 col', description: '', type: 'structure', category: 'structure' },
  {
    id: 'heading-3-col',
    name: 'Heading + 3 col',
    description: '',
    type: 'structure',
    category: 'structure',
  },
  {
    id: '2-by-2-grid',
    name: '2x2 grid',
    description: '',
    type: 'structure',
    category: 'structure',
  },
];

// eslint-disable-next-line no-restricted-syntax
export default createStore({
  modules: {
    // variables: variablesModule,
  },
  state: {
    user: null,
    token: null,
    userInfo: null,
    userSites: null,
    isSafari: false,
    figmaFileName: null,
    selectedSiteId: null,
    authorizationCode: null,

    clipboardData: null,
    needsToConfirmCopy: false,

    isLoggedIn: false,
    currentSelection: null,
    hasImagesToCopy: false,
    isFirstTimeOnPlugin: true,
    copiedSuccessfully: false,
    isCopyButtonLoading: false,
    hasReadDesignSystemNotification: false,

    textSizeUnit: 'px',
    remBaseValue: '16',
    cssClassPrefix: 'figma',
    variableNumberUnit: 'px',
    textStyleClassPrefix: 'Text',
    effectStyleClassPrefix: 'Effect',

    showNotificationsNumber: null,
    modal: { show: false, data: {} },
    warning: { type: null, data: {} },
    notification: { show: false, data: {} },
    currentPage: { name: 'home', params: {} },
    banner: {
      show: false,
      message: 'Safari not supported. Use Webflow in Chrome.',
    },

    // New Layout
    preBuiltComponents,
    isScrolling: false, // To add shadow to Layout sticky header
    designSystemSyncSelectedTab: 'components', // components || variables

    variablesToSync: [],
    componentsToSync: [],
    syncedSuccessfully: false,

    // 2.0
    isLoading: false,
    isLoadingVariablesCollection: false,
    selectedTab: 'layers',
    errorObject: {
      show: false,
      code: null,
      error: null,
    },
    navigationTabs: [
      { id: 'layers', name: 'Layers', icon: 'layers' },
      { id: 'variables', name: 'Variables', icon: 'variables' },
      { id: 'styles', name: 'Styles', icon: 'styles' },
    ],

    // Layers Page:
    maxLayers: 400,
    layersSuccessSource: null, // 'sync' | 'copy'
    layersSyncState: 'waiting', // 'waiting' | 'ready' | 'success' | 'error'
    hasSelectedLayers: false,
    hasLayersSyncDetails: false, // understsand if we have layers information to sync (this is true after 'continue' button is clicked)
    layersToSync: [], // These are the Figma layers that we already retrieved their info from the Figma API
    copyData: { layerId: null, startTime: null },

    // Variables Page:
    variableLibraries: [],
    variablesSyncState: 'ready', // 'ready' | 'success' | 'error'
    selectedCollection: null,
    selectedCollectionDetails: {
      collectionId: null,
      collectionKey: null,
      local: true,
    },
    selectedVariablesMap: [],
    selectedVariableGroups: [],
    depthValue: 16,
    selectedMode: null,

    // Styles Page:
    stylesSyncState: 'ready', // 'ready' | 'success' | 'error'
    stylesToSync: [],
    selectedStylesMap: [],
    selectedStylesGroups: [],

    // Images Page:
    maxImageSize: 4,
    maxFiles: 50,
    images: [],
    availableExtensions: ['png', 'jpg', 'svg'],
    availableScales: [0.5, 0.75, 1, 1.5, 2, 3, 4],
    maxMbImageSize: 5,
    imagesSyncState: 'ready', // 'success' | 'error'
    imagesToUpload: {
      total: 0,
      completed: 0,
    },
  },
  getters: {
    isAdmin(state) {
      // TODO vulnerability?
      return (state.user && state.user.email === 'john@makers.so') ||
        (state.user && state.user.email === 'bryant@webflow.com') ||
        (state.user && state.user.email === 'joao.damaia@webflow.com') ||
        (state.user && state.user.email === 'labs@webflow.com')
        ? true
        : false;
    },
    isWebflowUser(state) {
      return state.user && state.user.email.endsWith('@webflow.com');
    },
    selectedSiteShortName(state) {
      return state.userSites.find((site) => site.id === state.selectedSiteId)?.shortName ?? '';
    },
    selectedSiteName(state) {
      return state.userSites.find((site) => site.id === state.selectedSiteId)?.displayName ?? '';
    },
    webflowClientId() {
      return import.meta.env.VITE_WEBFLOW_CLIENT_ID;
    },
    selectedWebflowSiteUrlWithApp(state, getters) {
      return `https://${getters.selectedSiteShortName}.design.webflow.com/?app=${getters.webflowClientId}`;
    },
    selectedWebflowSiteUrl(state, getters) {
      return `https://${getters.selectedSiteShortName}.design.webflow.com`;
    },

    // Variables
    hasCollectionDetails(state) {
      return (
        state.selectedCollectionDetails.collectionId !== null &&
        state.selectedCollectionDetails.collectionKey !== null
      );
    },
    selectedVariables(state) {
      return state.selectedVariablesMap
        .map((v) => (v.isSelected ? v : null))
        .filter((v) => v !== null);
    },
    // Styles
    selectedStyles(state) {
      return state.selectedStylesMap
        .map((s) => (s.isSelected ? s : null))
        .filter((v) => v !== null);
    },
    // Images
    exportsGroup(state) {
      return state.images.filter((i) => i.group === 'exportsGroup');
    },
    layersGroup(state) {
      return state.images.filter((i) => i.group === 'layersGroup');
    },
    // Error Handling
    hasError(state) {
      return state.errorObject.show;
    },
    errorTitle(state, getters) {
      if (!getters.hasError) return '';
      return setErrorTitle(state.errorObject);
    },
    errorMessage(state, getters) {
      if (!getters.hasError) return '';
      return setErrorMessage(state.errorObject);
    },
    errorCode(state) {
      return state.errorObject.code;
    },
    error(state) {
      return state.errorObject.error;
    },
  },
  actions: {
    // Old Images API
    async uploadImagesToS3Bucket(_, data) {
      return await syncApi.uploadImagesToS3Bucket(data).then((response) => {
        return response.data;
      });
    },
    async signFile(_, data) {
      return await syncApi
        .signFile(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },

    // Sync Assets API
    async uploadImagesToSyncAssetsAPI(_, data) {
      return await syncApi.uploadImagesToSyncAssetsAPI(data).then((response) => {
        return response.data;
      });
    },
    async uploadImagesToSyncS3Bucket(_, data) {
      return await syncApi.uploadImagesToSyncS3Bucket(data).then((response) => {
        return response;
      });
    },

    // Authentication
    async generateKeys() {
      return await authApi.generateKeys().then((response) => {
        return response.data;
      });
    },
    async getAuthenticatedUser(_, data) {
      return await authApi.getAuthenticatedUser(data).then((response) => {
        return response.data;
      });
    },
    async getUserData(_, data) {
      return await authApi
        .getUserData(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async logUserOut(_, data) {
      return await authApi
        .logUserOut(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async deleteKeys(_, data) {
      return await authApi
        .deleteKeys(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },

    // Webflow API
    // Pre Built Components
    async storePreBuiltComponentJson(_, data) {
      return await authApi.storePreBuiltComponentJson(data).then((response) => {
        return response;
      });
    },

    // Sync Data
    async createSyncData(_, data) {
      return await syncApi.createSyncData(data).then((response) => {
        return response;
      });
    },

    async updateSelectedSite({ commit }, { site }) {
      commit('setSelectedSiteId', site.id);
      await dispatch('setRootPropertyValue', { prop: 'selectedSiteId', value: site.id });
    },
  },
  mutations: {
    setSelectedTab(state, value) {
      if (state.errorObject.show) {
        state.errorObject.show = false;
        state.errorObject.code = null;
        state.errorObject.error = null;
      }
      state.selectedTab = value;
    },

    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setIsSafari(state, value) {
      state.isSafari = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setUserSites(state, value) {
      state.userSites = value;
    },
    setSelectedSiteId(state, value) {
      setContext({ selectedSiteId: value });
      state.selectedSiteId = value;
    },
    setClipboardData(state, value) {
      state.clipboardData = value;
    },
    setNeedsToConfirmCopy(state, value) {
      state.needsToConfirmCopy = value;
    },
    setHasImagesToCopy(state, value) {
      state.hasImagesToCopy = value;
    },
    setIsFirstTimeOnPlugin(state, value) {
      state.isFirstTimeOnPlugin = value;
    },
    setHasReadDesignSystemNotification(state, value) {
      state.hasReadDesignSystemNotification = value;
    },
    setAuthorizationCode(state, value) {
      state.authorizationCode = value;
    },
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setCurrentSelection(state, value) {
      state.currentSelection = value;
    },
    setCopyButtonLoading(state, value) {
      state.isCopyButtonLoading = value;
    },
    setCopiedSuccessfully(state, value) {
      state.copiedSuccessfully = value;
    },
    setModal(state, value) {
      state.modal = value;
    },
    setNotification(state, value) {
      state.notification = value;
    },
    setBannerVisibility(state, value) {
      state.banner.show = value;
    },
    setWarning(state, value) {
      state.warning = value;
    },
    setShowNotificationsNumber(state, value) {
      state.showNotificationsNumber = value;
    },
    setCssClassPrefix(state, value) {
      state.cssClassPrefix = value;
    },
    setTextSizeUnit(state, value) {
      state.textSizeUnit = value;
    },
    setVariableNumberUnit(state, value) {
      state.variableNumberUnit = value;
    },
    setRemBaseValue(state, value) {
      state.remBaseValue = value;
    },
    setTextStyleClassPrefix(state, value) {
      state.textStyleClassPrefix = value;
    },
    setEffectStyleClassPrefix(state, value) {
      state.effectStyleClassPrefix = value;
    },
    setComponentsToSync(state, value) {
      state.componentsToSync = value;
    },
    setVariablesToSync(state, value) {
      state.variablesToSync = value;
    },
    setSyncedSuccessfully(state, value) {
      state.syncedSuccessfully = value;
    },
    setIsScrolling(state, value) {
      state.isScrolling = value;
    },
    setDesignSystemSyncSelectedTab(state, value) {
      state.designSystemSyncSelectedTab = value;
    },
    setFigmaFileName(state, value) {
      state.figmaFileName = value;
    },
    setWriteKey(state, value) {
      state.writeKey = value;
    },

    // 2.0 - Layers
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setIsLoadingVariablesCollection(state, value) {
      state.isLoadingVariablesCollection = value;
    },
    setHasSelectedLayers(state, value) {
      state.hasSelectedLayers = value;
    },
    setLayersToSync(state, value) {
      state.layersToSync = value;
    },
    setCopyData(state, value) {
      state.copyData = value;
    },
    setLayersSuccessSource(state, value) {
      state.layersSuccessSource = value;
    },
    setLayersSyncState(state, value) {
      state.layersSyncState = value;
    },
    setHasLayersSyncDetails(state, value) {
      state.hasLayersSyncDetails = value;
    },
    // 2.0 - Images
    setImages(state, value) {
      state.images = value;
    },
    setNewPropValueImage(state, { id, prop, value }) {
      const image = state.images.find((img) => img.id === id);
      if (image) {
        image[prop] = value;
      }
    },
    setImagesSyncState(state, value) {
      state.imagesSyncState = value;
    },
    // 2.0 - Variables
    setVariablesSyncState(state, value) {
      state.variablesSyncState = value;
    },
    setSelectedCollection(state, value) {
      state.selectedCollection = value;
    },
    setSelectedCollectionDetails(state, value) {
      state.selectedCollectionDetails = value;
    },
    setSelectedVariablesMap(state, value) {
      state.selectedVariablesMap = value;
    },
    setSelectedVariableGroups(state, value) {
      state.selectedVariableGroups = value;
    },
    setSelectedMode(state, value) {
      state.selectedMode = value;
    },
    setVariableLibraries(state, value) {
      state.variableLibraries = value;
    },
    // 2.0 - Styles
    setStylesToSync(state, value) {
      state.stylesToSync = value;
    },
    setStylesSyncState(state, value) {
      state.stylesSyncState = value;
    },
    setSelectedStylesMap(state, value) {
      state.selectedStylesMap = value;
    },
    setSelectedStylesGroups(state, value) {
      state.selectedStylesGroups = value;
    },
    // Error Handling
    setErrorObject(state, value) {
      state.errorObject = value;
    },

    // Images Upload
    setImagesToUpload(state, value) {
      state.imagesToUpload = value;
    },
  },
});
