<template>
  <button
    :class="{ 'is-disabled': isLoading }"
    @click="handleSendErrorReportButtonClick"
    class="wf-main-button wf-open-in-webflow-button"
  >
    <span v-if="!isLoading">Send us error report</span>
    <template v-else>
      <span class="small-loader"></span>
      <span>Sending...</span>
    </template>
  </button>
</template>

<script>
import { dispatch } from '@/figma/ui-message-handler';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { sendErrorReport } from '@/api/error-report-api';
export default {
  computed: {
    ...mapState(['isLoading', 'selectedSiteId']),
    ...mapGetters(['error']),
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async handleSendErrorReportButtonClick() {
      this.setIsLoading(true);
      try {
        await sendErrorReport({ site_id: this.selectedSiteId, error: this.error });
        this.$emit('handleSendErrorReportButtonClick');
        dispatch('notify', '✅ Thank you! Error report sent successfully');
        this.setIsLoading(false);
      } catch (e) {
        dispatch('notify', `❌ Couldn't send error report. Contact us at labs@webflow.com`);
        this.setIsLoading(false);
      }
    },
  },
};
</script>
