<template>
  <DropdownMenu
    class="wf-options-menu-dropdown"
    direction="right"
    containerZIndex="9999"
    overlayZIndex="9998"
    :overlay="false"
    transition="none"
    :withDropdownCloser="true"
  >
    <template #trigger>
      <button class="menu flex-center" :class="{ 'is-selected': isSecondaryPage }">
        <WfIcon icon="settings-dark" />
      </button>
    </template>
    <template #body>
      <div class="dropdown-menu" :class="{ disabled: isLoading }">
        <div
          v-if="isAdmin"
          class="menu-item"
          @click="setCurrentPage({ name: 'admin', params: {} })"
          style="margin-bottom: 8px"
        >
          Admin
        </div>

        <a
          target="_blank"
          class="menu-item"
          href="https://webflow.com"
          dropdown-closer
          :class="{ disabled: isLoading }"
        >
          <div class="icon-wrapper flex-center">
            <WfIcon icon="webflow-icon" />
          </div>
          <div>Dashboard</div>
        </a>
        <div
          dropdown-closer
          class="menu-item"
          :class="{ 'is-selected': item.id === selectedTab, disabled: isLoading }"
          :key="item.id"
          v-for="item in settingsItems"
          @click="setSelectedTab(item.id)"
        >
          <div class="icon-wrapper flex-center">
            <WfIcon :icon="item.icon" />
          </div>
          <div>{{ item.name }}</div>
        </div>

        <a
          target="_blank"
          class="menu-item"
          href="https://help.webflow.com/hc/en-us/articles/33961260854675-Figma-to-Webflow-plugin"
          dropdown-closer
          :class="{ disabled: isLoading }"
        >
          <div class="icon-wrapper flex-center">
            <WfIcon icon="help" />
          </div>
          <div>Help</div>
        </a>
        <WfLogOutButton :is-button="false" @updateIsLoading="updateLoading" />
      </div>
    </template>
  </DropdownMenu>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';
import WfIcon from '@/components/WfIcon.vue';
import WfLogOutButton from './WfLogOutButton.vue';
import { trackEvent, PLUGIN_ADDITIONAL_ACTION_TAKEN } from '@/helpers/analytics';

export default {
  components: {
    DropdownMenu,
    WfIcon,
    WfLogOutButton,
  },
  data() {
    return {
      isLoading: false,
      selectedStyleId: '',
      settingsItems: [
        { id: 'layouts', name: 'Layouts', icon: 'layouts' },
        { id: 'settings', name: 'Settings', icon: 'settings' },
      ],
    };
  },
  watch: {
    copiedSuccessfully(oldVal, newVal) {
      if (newVal === true) {
        this.selectedStyleId = '';
      }
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['isCopyButtonLoading', 'copiedSuccessfully', 'selectedTab']),
    isSecondaryPage() {
      return (
        this.selectedTab === 'settings' ||
        this.selectedTab === 'layouts' ||
        this.selectedTab === 'help'
      );
    },
  },
  methods: {
    ...mapMutations(['setSelectedTab', 'setCopyButtonLoading']),

    handleCopyStyles(type) {
      this.selectedStyleId = type;
      this.setCopyButtonLoading(true);
      const payload = {
        nodes: this.currentSelection,
        type,
      };
      dispatch('handleCopyStyles', payload);

      const actionName =
        type === 'all'
          ? 'Copy all text and color styles'
          : type === 'text'
            ? 'Copy text styles'
            : 'Copy color styles';
      trackEvent(PLUGIN_ADDITIONAL_ACTION_TAKEN, { actionName });
    },
    updateLoading(value) {
      this.isLoading = value;
    },
  },
};
</script>
