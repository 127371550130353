<template>
  <div class="wf-layer-copy">
    <button
      v-if="!needsToConfirmCopy"
      class="wf-button wf-button--secondary"
      @click="copyLayer"
      :disabled="isLoading"
    >
      {{ layerIsLoading && isLoading && layersSuccessSource === 'copy' ? 'Copying...' : 'Copy' }}
    </button>
    <WfLayerConfirmCopy v-else />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';

import WfLayerConfirmCopy from './WfLayerConfirmCopy.vue';
export default {
  components: {
    WfLayerConfirmCopy,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingLayerId: null,
    };
  },
  computed: {
    ...mapState(['user', 'isLoading', 'layersSuccessSource', 'needsToConfirmCopy']),
    layerIsLoading() {
      return this.isLoadingLayerId === this.layer.id;
    },
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setLayersSuccessSource', 'setCopyData']),
    copyLayer() {
      this.isLoadingLayerId = this.layer.id;
      this.setIsLoading(true);
      this.setCopyData({ layerType: this.layer.type, startTime: performance.now() });
      this.setLayersSuccessSource('copy');
      dispatch('handleCopy', { id: this.layer.id, userId: this.user.id });
    },
  },
};
</script>
