<template>
  <div class="wf-settings-page">
    <div class="wf-settings-group" v-for="group in settingsGroups" :key="group.groupName">
      <div class="wf-group-content">{{ group.groupName }}</div>
      <WfSettingsRow v-for="option in group.settingsOptions" :key="option.id" :option="option" />
    </div>
  </div>
</template>
<script>
import WfSettingsRow from './WfSettingsRow.vue';
export default {
  components: {
    WfSettingsRow,
  },
  data() {
    return {
      settingsGroups: [
        {
          groupName: 'Classes',
          settingsOptions: [
            {
              id: 'cssClassPrefix',
              type: 'input',
              label: 'Layers prefix',
              defaultValue: '',
              tooltip:
                'Used for classes created for layers. Add a prefix to easily identify generated classes.',
            },
            {
              id: 'textStyleClassPrefix',
              type: 'input',
              label: 'Text styles prefix',
              defaultValue: 'Text',
              tooltip:
                'Used for classes created for text styles. Add a prefix to easily identify generated classes.',
            },
            {
              id: 'effectStyleClassPrefix',
              type: 'input',
              label: 'Effect styles prefix',
              defaultValue: 'Effect',
              tooltip:
                'Used for classes created for effect styles. Add a prefix to easily identify generated classes.',
            },
          ],
        },
        {
          groupName: 'Units',
          settingsOptions: [
            {
              id: 'textSizeUnit',
              type: 'dropdown',
              label: 'Font size unit',
              defaultValue: 'px',
              options: ['px', 'rem', 'em'],
              tooltip: 'Used as the default unit for any font size styles created by sync or copy.',
            },
            {
              id: 'variableNumberUnit',
              type: 'dropdown',
              label: 'Size variable unit',
              defaultValue: 'px',
              options: ['px', 'rem', 'em'],
              tooltip: 'Used as the default unit for any size variable created by sync.',
            },
            {
              id: 'remBaseValue',
              type: 'input',
              label: 'Base font size',
              defaultValue: '16',
              tooltip:
                'Used for calculating rem and em values. This should match the font size on the body of your site.',
            },
          ],
        },
      ],
    };
  },
};
</script>
