<template>
  <div class="wf-images-table-group-select-all" @click="selectAll">
    <WfCheckbox :status="isSelected ? 'selected' : isMixed ? 'mixed' : 'unselected'" />
    <span>{{ name }}</span>
  </div>
</template>
<script>
import WfCheckbox from '@/components/mainContent/WfCheckbox.vue';
export default {
  components: {
    WfCheckbox,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isMixed: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    selectAll() {
      this.$emit('select-all');
    },
  },
};
</script>
