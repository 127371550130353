<template>
  <div class="wf-checkbox" :class="{ unselected: isUnselected }">
    <WfIcon :icon="isMixed ? 'hyphen' : 'checkmark'" />
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  props: {
    status: {
      type: String,
      default: 'selected',
    },
  },
  computed: {
    isUnselected() {
      return this.status === 'unselected';
    },
    isMixed() {
      return this.status === 'mixed';
    },
  },
};
</script>
