<template>
  <div class="wf-settings-row">
    <div class="start">
      {{ option.label }}
      <WfIcon icon="question-mark" :id="option.id" />
    </div>
    <div class="end">
      <WfSettingsRowValueInput v-if="option.type === 'input'" :option="option" />
      <WfSettingsRowValueDropdown v-else-if="option.type === 'dropdown'" :option="option" />
    </div>
  </div>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import WfIcon from '@/components/WfIcon.vue';
import WfSettingsRowValueInput from './WfSettingsRowValueInput.vue';
import WfSettingsRowValueDropdown from './WfSettingsRowValueDropdown.vue';

export default {
  components: {
    WfIcon,
    WfSettingsRowValueInput,
    WfSettingsRowValueDropdown,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    this.setupTooltip();
  },
  methods: {
    setupTooltip() {
      this.tooltip = tippy(`#${this.option.id}`, {
        maxWidth: 200,
        theme: 'custom',
        placement: 'top',
        content: this.option.tooltip,
      });
    },
  },
};
</script>
