<template>
  <div class="menu-title" v-for="library in options" :key="library.key">
    <span class="text-ellipsis" :title="library.name">{{ library.name }}</span>
    <div
      class="menu-item"
      dropdown-closer
      :key="option.id"
      v-for="option in library.variableCollections"
      @click.stop="handleSelected(option)"
    >
      <div
        class="item-icon-wrapper"
        :class="{ 'item-icon-wrapper--show': option.id === selectedOption }"
      >
        <WfIcon icon="checkmark" color="#FFF" />
      </div>
      <span class="text-ellipsis" :title="option.name">{{ option.name }}</span>
    </div>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  emits: ['selected', 'dropdownCloser'],
  components: {
    WfIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [String, null],
      default: null,
      required: true,
    },
  },
  methods: {
    handleSelected(option) {
      this.$emit('selected', option);
      this.$emit('dropdownCloser');
    },
  },
};
</script>
