<template>
  <div class="wf-page-content">
    <WfIcon icon="layers-large" />
    <p>
      <strong>No layers selected</strong>
      Select any frames or components with auto-layout to get started.
    </p>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
};
</script>
