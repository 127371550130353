<template>
  <div class="wf-table-cell wf-table-cell--header">
    <span v-if="modes.length === 1" class="text-ellipsis">{{ selectedMode?.name }}</span>
    <WfDropdown
      v-else
      :options="modes"
      :selected-option="selectedMode?.id"
      @selected="setMode"
      direction="right"
    >
      <span class="text-ellipsis">{{ selectedMode?.name }}</span>
      <div class="icon-wrapper">
        <WfIcon icon="chevron-down" />
      </div>
    </WfDropdown>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';
import WfDropdown from '@/components/mainContent/dropdown/WfDropdown.vue';
export default {
  name: 'WfVariableTableModes',
  components: {
    WfDropdown,
    WfIcon,
  },
  props: {
    modes: {
      type: Array,
      required: true,
    },
    selectedMode: {
      type: [Object, null],
      default: null,
      required: true,
    },
  },
  methods: {
    ...mapMutations(['setSelectedMode']),
    setMode(mode) {
      this.setSelectedMode(mode);
    },
  },
};
</script>
