<template>
  <DropdownMenu
    class="wf-selected-site-dropdown"
    direction="left"
    containerZIndex="9999"
    overlayZIndex="9998"
    :overlay="false"
    transition="none"
    :withDropdownCloser="true"
  >
    <template #trigger>
      <button class="menu">
        <span>{{ selectedOptionName }}</span>
        <div class="icon-wrapper">
          <WfIcon icon="caret-down" color="#B2B2B2" />
        </div>
      </button>
    </template>
    <template #body>
      <WfSetResponsiveOption
        :layer="layer"
        :options="options"
        :selected-option-id="selectedOptionId"
        @set-selected-option="setSelectedOption"
      />
    </template>
  </DropdownMenu>
</template>
<script>
import { dispatch } from '@/figma/ui-message-handler';
import { updateLayersToSyncProperty } from '@/helpers/layers/layersHandler';
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

import WfIcon from '@/components/WfIcon.vue';
import WfSetResponsiveOption from './WfSetResponsiveOption.vue';

export default {
  components: {
    DropdownMenu,

    WfIcon,
    WfSetResponsiveOption,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOptionId: 'none',
      options: [
        { id: 'none', name: 'Not Responsive', wfComponent: '' },
        { id: 'desktop', name: 'Tablet', wfComponent: 'MediumColumns' },
        { id: 'tablet', name: 'Mobile Landscape', wfComponent: 'SmallColumns' },
        { id: 'phone', name: 'Mobile Portrait', wfComponent: 'TinyColumns' },
      ],
    };
  },
  mounted() {
    this.selectedOptionId = this.getResponsiveOption(this.layer);
  },
  computed: {
    selectedOptionName() {
      return this.options.find((o) => o.id === this.selectedOptionId)?.name || 'Not Responsive';
    },
  },
  methods: {
    setSelectedOption(option) {
      this.selectedOptionId = option.id;
      const payload = {
        id: this.layer.id,
        key: 'wfComponent',
        trigger: 'responsiveChange',
        value: option.wfComponent,
      };
      updateLayersToSyncProperty(this.layer, 'wfComponent', option.wfComponent);
      dispatch('updateNode', payload);

      dispatch('updateLayerResponsiveStyle', {
        nodeId: this.layer.id,
        responsiveMode: option.wfComponent,
      });
    },
    getResponsiveOption(layer) {
      const layerOptionId = this.getLayerOptionId(layer);
      return this.options.find((o) => o.id === layerOptionId).id || 'none';
    },
    getLayerOptionId(layer) {
      switch (layer.wfComponent) {
        case 'MediumColumns':
          return 'desktop';
        case 'SmallColumns':
          return 'tablet';
        case 'TinyColumns':
          return 'phone';
        default:
          return 'none';
      }
    },
  },
};
</script>
