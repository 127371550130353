<template>
  <div
    class="wf-pre-built-component"
    id="prebuiltComponentTooltip"
    @click="handleComponentClick()"
    :class="{ 'is-loading': isLoadingComponent }"
  >
    <div class="image">
      <WfLayoutsPreBuiltComponentThumbnail :thumbnail="component.id" />
    </div>
    <div class="name">{{ component.name }}</div>
    <div v-if="isLoadingComponent && selectedComponent.id === component.id" class="loader-wrapper">
      <span class="small-loader"></span>
    </div>
  </div>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import WfLayoutsPreBuiltComponentThumbnail from './WfLayoutsPreBuiltComponentThumbnail.vue';
export default {
  components: {
    WfLayoutsPreBuiltComponentThumbnail,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    isLoadingComponent: {
      type: Boolean,
      required: true,
    },
    selectedComponent: {
      type: [Object, null],
      required: true,
    },
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    this.setupTooltip();
  },
  methods: {
    handleComponentClick() {
      this.$emit('handle-select-component', this.component);
    },
    setupTooltip() {
      this.tooltip = tippy('#prebuiltComponentTooltip', {
        maxWidth: 200,
        theme: 'custom',
        placement: 'top',
        content: 'Click to add to canvas',
      });
    },
  },
};
</script>
