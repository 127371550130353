<template>
  <div class="wf-layouts-page">
    <div class="wf-sticky-header">
      <WfDropdown
        :class="{ 'is-disabled': isLoadingComponent }"
        :options="preBuiltComponentsCategories"
        :selected-option="selectedCategoryId"
        @selected="handleSelectedCategory"
        direction="left"
      >
        <span>{{ selectedCategoryName }}</span>
        <div class="icon-wrapper">
          <WfIcon icon="caret-down" />
        </div>
      </WfDropdown>
    </div>
    <div class="wf-pre-built-components" :class="{ 'is-loading-component': isLoadingComponent }">
      <WfLayoutsPreBuiltComponent
        v-for="component in selectedPreBuiltComponents"
        :key="component.id"
        :component="component"
        :selected-component="selectedComponent"
        :is-loading-component="isLoadingComponent"
        @handle-select-component="handleSelectComponent"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';

import WfIcon from '@/components/WfIcon.vue';
import WfDropdown from '@/components/mainContent/dropdown/WfDropdown.vue';
import WfLayoutsPreBuiltComponent from './WfLayoutsPreBuiltComponent.vue';
export default {
  components: {
    WfIcon,
    WfDropdown,
    WfLayoutsPreBuiltComponent,
  },
  data() {
    return {
      selectedComponent: null,
      isLoadingComponent: false,
      selectedCategoryId: 'all',
      preBuiltComponentsCategories: [
        { id: 'all', name: 'All' },
        { id: 'layout', name: 'Layouts' },
        { id: 'structure', name: 'Structure' },
      ],
    };
  },
  mounted() {
    handleEvent('componentWasAddedSuccessfully', () => this.handleComponentWasAddedSuccessfully());
  },
  computed: {
    ...mapState(['preBuiltComponents']),
    selectedCategoryName() {
      return this.preBuiltComponentsCategories.find((c) => c.id === this.selectedCategoryId).name;
    },
    selectedPreBuiltComponents() {
      if (this.selectedCategoryId === 'all') {
        return this.preBuiltComponents;
      }
      return this.preBuiltComponents.filter((c) => c.category === this.selectedCategoryId);
    },
  },
  methods: {
    handleSelectedCategory(category) {
      this.selectedCategoryId = category.id;
    },
    handleSelectComponent(component) {
      this.selectedComponent = component;
      this.isLoadingComponent = true;
      const url = `https://figma-to-webflow.s3.us-west-1.amazonaws.com/webflow/pre-built-components/${component.id}.json?nocache=${new Date().getTime()}`;
      fetch(url)
        .then((response) => {
          if (!response.ok) throw new Error('HTTP error ' + response.status);
          return response.json();
        })
        .then((data) => {
          dispatch('createComponent', data);
          // trackEvent(PREBUILT_COMPONENT_ADDED, { componentType: this.component.id });
          return;
        })
        .catch((error) => {
          // log(error);
          console.error('ERROR: ', error);
          this.selectedComponent = null;
          this.isLoadingComponent = false;
        });
    },
    handleComponentWasAddedSuccessfully() {
      this.isLoadingComponent = false;
      this.selectedComponent = null;
    },
  },
};
</script>
