import store from '@/store';

export const populateSelectedStyles = (styles) => {
  // Main function to update the selected Styles
  const updateSelectedStyles = (styles, selectedStyles) => {
    const selectedStylesMap = getSelectedStylesMap(styles);
    // Create the updated list of selected Styles using the merge function
    const updatedSelectedStyles = mergeSelectedStyles(selectedStyles, selectedStylesMap);
    return updatedSelectedStyles;
  };

  // get all styles from the collection, recursively
  const getSelectedStylesMap = (styles) => {
    const styleGroups = getStyleGroups([styles]);
    return styleGroups.flatMap((group) =>
      group.styles.map(({ id, name, styleLess, styleType }) => ({
        id,
        groupId: group.id,
        name,
        styleLess,
        styleType,
        isSelected: true,
      }))
    );
  };

  // Helper function to merge two arrays based on a comparison function
  const mergeSelectedStyles = (selectedStyles, selectedStylesMap) => {
    // Add missing styles from selectedStylesMap to selectedStyles
    const addedStyles = selectedStylesMap.filter(
      (style) => !selectedStyles.some((v) => v.id === style.id)
    );

    // Remove styles from selectedStyles that are not in selectedStylesMap
    const filteredSelectedStyles = selectedStyles.filter((style) =>
      selectedStylesMap.some((v) => v.id === style.id)
    );

    // Combine both added and filtered selected styles
    return [...filteredSelectedStyles, ...addedStyles];
  };

  const updatedStyles = updateSelectedStyles(styles, store.state.selectedStylesMap);
  // Commit the updated selected styles back to the store
  store.commit('setSelectedStylesMap', updatedStyles);
};
export const populateSelectedStyleGroups = (styles) => {
  const updateSelectedStyleGroups = (styles, selectedStylesGroups) => {
    const selectedGroupsMap = getSelectedGroupsMap(styles);
    const updatedSelectedGroups = mergeSelectedStyles(selectedStylesGroups, selectedGroupsMap);
    return updatedSelectedGroups;
  };
  const getSelectedGroupsMap = (styles) => {
    const styleGroups = getStyleGroups([styles]);
    return styleGroups.map((group) => ({ id: group.id, name: group.name, isSelected: true }));
  };
  const mergeSelectedStyles = (selectedStylesGroups, selectedGroupsMap) => {
    const addedGroups = selectedGroupsMap.filter(
      (group) => !selectedStylesGroups.some((v) => v.id === group.id)
    );
    const filteredSelectedGroups = selectedStylesGroups.filter((group) =>
      selectedGroupsMap.some((v) => v.id === group.id)
    );

    // Combine both added and filtered selected Groups
    return [...filteredSelectedGroups, ...addedGroups];
  };

  const updatedSelectedStyleGroups = updateSelectedStyleGroups(
    styles,
    store.state.selectedStylesGroups
  );
  store.commit('setSelectedStylesGroups', updatedSelectedStyleGroups);
};
export const toggleSelectedItem = (itemId, itemType) => {
  if (itemType === 'group') {
    toggleGroup(itemId);
  } else if (itemType === 'style') {
    toggleStyle(itemId);
  }
  toggleItem(itemId, itemType);
};

/**
 * Toggle functions
 */
const toggleItem = (itemId, itemType) => {
  // Find the item in the store
  const array = store.state[itemType === 'group' ? 'selectedStylesGroups' : 'selectedStylesMap'];
  const item = array.find((item) => item.id === itemId);
  // Toggle the isSelected property
  item.isSelected = !item.isSelected;
  // Commit the updated item back to the store
  store.commit(
    `${itemType === 'group' ? 'setSelectedStylesGroups' : 'setSelectedStylesMap'}`,
    array
  );
};
const toggleGroup = (groupId) => {
  const group = store.state.selectedStylesGroups.find((g) => g.id === groupId);
  const updatedStyles = store.state.selectedStylesMap.map((s) =>
    s.groupId === groupId ? { ...s, isSelected: !group.isSelected } : s
  );
  store.commit('setSelectedStylesMap', updatedStyles);
};
const toggleStyle = (styleId) => {
  const { state } = store;
  const style = state.selectedStylesMap.find((v) => v.id === styleId);
  const group = state.selectedStylesGroups.find((g) => g.id === style.groupId);

  const allStylesFromGroup = state.selectedStylesMap.filter((v) => v.groupId === style.groupId);
  const allStylesStatus = getAllStylesStatus(allStylesFromGroup);

  if (allStylesStatus !== 'mixed') {
    const updatedGroups = updateGroupSelection(
      state.selectedStylesGroups,
      group.id,
      allStylesStatus === 'selected'
    );
    store.commit('setSelectedStylesGroups', updatedGroups);
  }
};
export const toggleRootGroup = (groupId, value) => {
  const updatedStyles = store.state.selectedStylesMap.map((s) =>
    s.groupId === groupId ? { ...s, isSelected: value } : s
  );
  store.commit('setSelectedStylesMap', updatedStyles);
};

/**
 * Helper functions
 */
export const getGroupSelectionStatus = (group) => {
  const { state } = store;
  const allStylesFromGroup = state.selectedStylesMap.filter((s) => s.groupId === group.id);
  if (allStylesFromGroup.length === 0) {
    return getSubGroupStatus(group);
  }
  const groupStatus = getAllStylesStatus(allStylesFromGroup);
  if (groupStatus === 'mixed') {
    return 'mixed';
  }

  const subGroupStatuses = (group.styleGroups || []).map(getGroupSelectionStatus);

  if (subGroupStatuses.includes('mixed')) {
    return 'mixed';
  }

  const { allSelected, allUnselected } = getAllSelectedAndUnselected(subGroupStatuses);

  const isGroupSelected = groupStatus === 'selected' && allSelected;
  const isGroupUnselected = groupStatus === 'unselected' && allUnselected;
  return isGroupSelected ? 'selected' : isGroupUnselected ? 'unselected' : 'mixed';
};
const getSubGroupStatus = (group) => {
  const subGroupStatuses = (group.styleGroups || []).map(getGroupSelectionStatus);
  const { allSelected, allUnselected } = getAllSelectedAndUnselected(subGroupStatuses);
  return allSelected ? 'selected' : allUnselected ? 'unselected' : 'mixed';
};
export const getRootGroupSelectionStatus = (children) => {
  const childrenStatus = children.map((child) => getGroupSelectionStatus(child));
  const { allSelected, allUnselected } = getAllSelectedAndUnselected(childrenStatus);
  return allSelected ? 'selected' : allUnselected ? 'unselected' : 'mixed';
};
const getAllSelectedAndUnselected = (statuses) => {
  const allSelected = statuses.every((status) => status === 'selected');
  const allUnselected = statuses.every((status) => status === 'unselected');
  return { allSelected, allUnselected };
};
const getAllStylesStatus = (styles) => {
  const allSelected = styles.every((s) => s.isSelected);
  const allUnselected = styles.every((s) => !s.isSelected);
  return allSelected ? 'selected' : allUnselected ? 'unselected' : 'mixed';
};
const getStyleGroups = (styles) => {
  const extractGroups = (group, styleId) => {
    const currentGroup = {
      id: group.id,
      styleId: styleId,
      name: group.name,
      isRoot: group.name === 'Root',
      styles: group?.styles ?? [],
      styleGroups: group?.styleGroups ?? [],
      isSelected: group?.isSelected ?? true,
    };

    const nestedGroups = group.styleGroups
      ? group.styleGroups.flatMap((g) => extractGroups(g, styleId))
      : [];

    return [currentGroup, ...nestedGroups];
  };

  return styles.flatMap((style) => {
    if ('effectStyleGroups' in style && 'textStyleGroups' in style) {
      // Merge effect and text style groups when we are iterating over the first style object
      style.styleGroups = [...style.effectStyleGroups, ...style.textStyleGroups];
    }
    if (!style.styleGroups) {
      return [];
    }

    return style.styleGroups.flatMap((g) => extractGroups(g, style.id));
  });
};
const updateGroupSelection = (groups, groupId, isSelected) =>
  groups.map((g) => (g.id === groupId ? { ...g, isSelected } : g));
