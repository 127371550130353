<template>
  <div class="wf-layers-page">
    <WfSelectLayersMessage v-if="layersSyncState === 'waiting' && !hasSelectedLayers" />
    <WfGetLayersInfoMessage v-else-if="layersSyncState === 'waiting' && hasSelectedLayers" />
    <WfLayersToSync v-else-if="layersSyncState === 'ready'" />
    <WfLayersSuccessMessage
      v-else-if="layersSyncState === 'success'"
      :source="layersSuccessSource"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { handleEvent } from '@/figma/ui-message-handler';

import WfLayersSuccessMessage from './WfLayersSuccessMessage.vue';
import WfGetLayersInfoMessage from './WfGetLayersInfoMessage.vue';
import WfSelectLayersMessage from './WfSelectLayersMessage.vue';
import WfLayersToSync from './WfLayersToSync.vue';

export default {
  components: {
    WfGetLayersInfoMessage,
    WfLayersSuccessMessage,
    WfSelectLayersMessage,
    WfLayersToSync,
  },
  mounted() {
    handleEvent('selectionChanged', () => {
      if (this.isLoading) {
        return;
      }

      this.setWarning({ type: null, data: {} });
      this.setIsLoading(false);
      this.setErrorObject({});
      this.setHasLayersSyncDetails(false);
      this.setLayersSuccessSource(null);
      if (this.layersSyncState !== 'waiting') {
        this.setLayersSyncState('waiting');
      }
    });
    handleEvent('setHasOneOrMoreFrameSelected', (value) =>
      this.setHasOneOrMoreFrameSelected(value)
    );
  },
  computed: {
    ...mapState([
      'isLoading',
      'layersSyncState',
      'hasSelectedLayers',
      'hasLayersSyncDetails',
      'layersToSync',
      'layersSuccessSource',
      'selectedSiteId',
    ]),
  },
  watch: {
    selectedSiteId: {
      handler() {
        this.setLayersSyncState('waiting');
      },
    },
  },
  methods: {
    ...mapMutations([
      'setHasSelectedLayers',
      'setHasLayersSyncDetails',
      'setWarning',
      'setLayersSyncState',
      'setLayersSuccessSource',
      'setIsLoading',
      'setErrorObject',
    ]),

    setHasOneOrMoreFrameSelected(value) {
      this.setHasSelectedLayers(value);
      if (!value) {
        this.setHasLayersSyncDetails(false);
      }
    },
  },
};
</script>
