<template>
  <div class="wf-variables-table wf-variables-table--is-loading">
    <div class="wf-variable-table-header">
      <div class="wf-table-cell wf-table-cell--header">
        <div class="skeleton-cell"></div>
      </div>
      <div class="wf-table-cell wf-table-cell--header">
        <div class="skeleton-cell"></div>
      </div>
    </div>
    <div class="wf-table-group">
      <div class="wf-table-row" v-for="i in rowsNumber" :key="i">
        <div class="wf-table-cell"><div class="skeleton-cell"></div></div>
        <div class="wf-table-cell"><div class="skeleton-cell"></div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rowsNumber: 3,
    };
  },
};
</script>
