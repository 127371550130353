<template>
  <div class="wf-table-row">
    <WfVariableTableCell :variable="variable" type="name" :is-root="isRoot" />
    <WfVariableTableCell :variable="variable" type="value" :is-root="isRoot" />
  </div>
</template>
<script>
import { mapState } from 'vuex';

import WfVariableTableCell from './WfVariableTableCell.vue';
export default {
  components: {
    WfVariableTableCell,
  },
  props: {
    variable: {
      type: Object,
      required: true,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['selectedVariablesMap', 'selectedMode']),
    mode() {
      return this.variable.modes.find((m) => m.modeId === this.selectedMode?.id);
    },
    variableValue() {
      return this.mode?.value;
    },
    variableType() {
      return this.mode?.type;
    },
  },
};
</script>
