<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import { mapState, mapMutations } from 'vuex';
import { handleEvent } from '@/figma/ui-message-handler';
import * as entities from 'entities';
import { triggerCopyToClipboard } from '@/helpers/helper';
import { createCopyEventObject } from '@/helpers/analytics';
import { trackEvent, FIGMA_COPY_COMPLETED } from '@/helpers/analytics';

export default {
  mounted() {
    handleEvent('copyToClipboard', async (json) => await this.copyToClipboard(json));
    document.addEventListener('copy', this.copyEventHandler);
  },
  beforeUnmount() {
    document.removeEventListener('copy', this.copyEventHandler);
  },
  computed: {
    ...mapState(['copyData', 'clipboardData']),
  },
  methods: {
    ...mapMutations([
      'setIsLoading',
      'setLayersSyncState',
      'setClipboardData',
      'setNeedsToConfirmCopy',
      'setCopyData',
    ]),
    async copyToClipboard(json) {
      if (json === '') {
        this.setIsLoading(false);
        return;
      }

      this.encodeJson(json);
      const success = triggerCopyToClipboard();
      if (!success) {
        this.setNeedsToConfirmCopy(true);
      }
    },
    copyEventHandler(e) {
      e.clipboardData.setData('text/html', this.clipboardData);

      this.setLayersSyncState('success');
      trackEvent(
        FIGMA_COPY_COMPLETED,
        createCopyEventObject(this.copyData.layerType, this.copyData.startTime)
      );
      this.setIsLoading(false);
      if (this.needsToConfirmCopy) {
        this.setNeedsToConfirmCopy(false);
      }
      e.preventDefault();
      this.setCopyData({ layerId: null, startTime: null });
    },
    encodeJson(json) {
      const encodedJson = entities.encodeHTML(json);
      const htmlString = this.getHtmlString(encodedJson);
      this.setClipboardData(htmlString);
    },
    getHtmlString(json) {
      return `<p xmlns="http://www.w3.org/1999/xhtml"><main data-clipboard="${json}" data-clipboard-source="figma-plugin"></main></p>`;
    },
  },
};
</script>
