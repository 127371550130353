<template>
  <div class="wf-images-page">
    <WfImagesTableLoading v-if="isFirstLoad" />
    <template v-else>
      <WfImagesTable v-if="imagesSyncState === 'ready'" />
      <WfImagesSuccessMessage v-else-if="imagesSyncState === 'success'" />
    </template>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';

import WfImagesTable from './WfImagesTable.vue';
import WfImagesSuccessMessage from './WfImagesSuccessMessage.vue';
import WfImagesTableLoading from './imagesTable/WfImagesTableLoading.vue';

export default {
  components: {
    WfImagesTable,
    WfImagesTableLoading,
    WfImagesSuccessMessage,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(['imagesSyncState', 'images', 'selectedSiteId']),
    isFirstLoad() {
      return !this.images.length;
    },
  },
  watch: {
    selectedSiteId: {
      handler() {
        this.setImages([]);
        this.getAllImages();
      },
    },
  },
  mounted() {
    if (this.isFirstLoad) this.getAllImages();

    handleEvent('setAllImages', ({ exportsGroup }) => {
      const images = exportsGroup.images.map((image) => {
        const blob = new Blob([new Uint8Array(image.bytes).buffer], {
          type: image.type === 'png' ? 'image/png' : 'image/svg+xml',
        });

        return {
          ...image,
          url: URL.createObjectURL(blob),
          group: 'exportsGroup',
          isSelected: true,
        };
      });

      this.setImages(images);
      this.isLoading = false;
    });
  },
  methods: {
    ...mapMutations(['setImages']),

    getAllImages() {
      dispatch('getAllImages');
    },
  },
};
</script>
