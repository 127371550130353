import store from '@/store';
export const errorHandling = (err) => {
  const code = err.status;
  const error = getErrorFromResponse(err);
  const errorObject = {
    show: true,
    code,
    error,
  };
  store.commit('setErrorObject', errorObject);
  store.commit('setIsLoading', false);
};
const getErrorFromResponse = (error) => {
  const message = !error.response ? error.message : error.response.data.errorCode; // We only read errorCode for 413 errors
  return {
    code: error.status,
    message,
    data: error?.data ?? error?.response?.data,
    headers: error?.config?.headers,
    method: error?.config?.method,
    url: error?.config?.url,
  };
};

export const setErrorTitle = ({ code, error }) => {
  const defaultTitle = 'Something went wrong';

  if (!code) return error.message || defaultTitle;

  switch (code) {
    case 400:
    case 401:
    case 403:
    case 404:
      return defaultTitle;
    case 406:
      return 'Layer cannot be synced';
    case 413:
      return getTooLargeErrorTitle(error);
    case 500:
      return 'Oops, something went wrong';
    default:
      return defaultTitle;
  }
};
export const setErrorMessage = (error) => {
  const code = error.code;
  if (!code)
    return 'We are not sure what went wrong. Please send us the error report so we can fix it as soon as possible.';
  switch (code) {
    case 400:
    case 401:
    case 403:
    case 404:
      return "It looks like you don't have permission to access this feature. Please sign out and restart the plugin.";
    case 406:
      return 'Make the layer visible or select another layer to sync.';
    case 413:
      return getTooLargeErrorMessage(error);
    case 500:
      return 'We are not sure what went wrong. Please send us the error report so we can fix it as soon as possible.';
    default:
      return 'We are not sure what went wrong. Please send us the error report so we can fix it as soon as possible.';
  }
};

const getTooLargeErrorTitle = ({ message }) => {
  if (!message || message === '') {
    return 'Your data is too large';
  } else if (message === 'too_many_files') {
    return 'Too many images';
  } else if (message === 'max_image_size') {
    return 'Image size too large';
  } else if (message === 'max_sync_size') {
    return 'Too many layers';
  }
};

const getTooLargeErrorMessage = (error) => {
  const { message } = error.error;
  if (!message || message === '') {
    return 'Select fewer layers in your Figma Canvas and try again.';
  } else if (message === 'too_many_files') {
    return `You have too many images (> ${store.state.maxFiles}) in the selected layers. Please review and try again.`;
  } else if (message === 'max_image_size') {
    return `Images size is too large (> ${store.state.maxImageSize} MB). Copy smaller sections of your site. Please try again.`;
  } else if (message === 'max_sync_size') {
    return `You have too many layers selected (> ${store.state.maxLayers}). Please select fewer layers and try again.`;
  }

  return message;
};

export const isAuthError = (error) => {
  return [401, 403].includes(error.status);
};
