<template>
  <template v-if="isGlobalLoading">
    <WfIsSafariScreen v-if="isSafari" />
    <div v-else class="global-loading">
      <div class="loader">Loading...</div>
      <div class="webflow-logo">
        <WfIcon icon="webflow-logo" />
      </div>
    </div>
  </template>
  <template v-else>
    <template v-if="isLoggedIn">
      <WfSelectedSite />
      <WfNavigationTabs />
      <WfSelectSiteMessage v-if="!selectedSiteId" />
      <WfMainContent v-else />

      <!-- <WfHomePage v-if="currentPage.name === 'home'" />

      <WfHelpPage v-else-if="currentPage.name === 'help'" />
      <WfSettingsPage v-else-if="currentPage.name === 'settings'" />

      <WfDashboardPage v-else-if="currentPage.name === 'dashboard'" />
      <WfAdminPage v-else-if="currentPage.name === 'admin'" :components="components" />

      <WfHandleCopyEventsRenderless /> -->
    </template>
    <WfOnboarding v-else />
  </template>
  <WfHandleAuthenticationTokenRenderless @set-is-global-loading="isGlobalLoading = $event" />
  <WfHandleCopyEventRenderless />
  <WfHandleImagesRenderless />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { trackEvent, PLUGIN_LAUNCHED } from '@/helpers/analytics';
import { initLogging, log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import { __VERSION__ } from '@/helpers/version';

import WfMainContent from '@/components/mainContent/WfMainContent.vue';
import WfSelectedSite from '@/components/selectedSite/WfSelectedSite.vue';
import WfNavigationTabs from '@/components/navigationTabs/WfNavigationTabs.vue';
import WfSelectSiteMessage from '@/components/selectedSite/WfSelectSiteMessage.vue';

import WfIcon from '@/components/WfIcon.vue';
import WfOnboarding from '@/components/WfOnboarding.vue';

import WfIsSafariScreen from '@/components/ui/WfIsSafariScreen.vue';
import WfHandleImagesRenderless from '@/components/WfHandleImagesRenderless.vue';
import WfHandleCopyEventRenderless from '@/components/WfHandleCopyEventRenderless.vue';
import WfHandleAuthenticationTokenRenderless from '@/components/WfHandleAuthenticationTokenRenderless.vue';

export default {
  components: {
    WfMainContent,
    WfSelectedSite,
    WfNavigationTabs,
    WfSelectSiteMessage,

    WfHandleAuthenticationTokenRenderless,
    WfHandleCopyEventRenderless,
    WfHandleImagesRenderless,
    WfIsSafariScreen,

    WfIcon,
    WfOnboarding,
  },
  data() {
    return {
      clipboardData: null,
      isGlobalLoading: true,
    };
  },
  mounted() {
    initLogging();
    trackEvent(PLUGIN_LAUNCHED);

    // Getters
    dispatch('getFigmaFileName');
    dispatch('getShowNotificationsNumber');
    dispatch('getRootPropertyValue', { prop: 'textSizeUnit', value: 'px' });
    dispatch('getRootPropertyValue', { prop: 'remBaseValue', value: '16' });
    dispatch('getRootPropertyValue', { prop: 'cssClassPrefix', value: 'null' });
    dispatch('getRootPropertyValue', { prop: 'variableNumberUnit', value: 'px' });
    dispatch('getRootPropertyValue', { prop: 'textStyleClassPrefix', value: 'Text' });
    dispatch('getRootPropertyValue', { prop: 'effectStyleClassPrefix', value: 'Effect' });
    dispatch('getRootPropertyValue', { prop: 'isFirstTimeOnPlugin', value: 'false' });
    dispatch('getRootPropertyValue', { prop: 'hasReadDesignSystemNotification', value: 'false' });

    // Setters
    handleEvent('logError', (error) => this.logError(error));
    handleEvent('showError', (error) => errorHandling(error));
    handleEvent('setWarningType', (data) => this.setWarning(data));
    handleEvent('remBaseValue', (value) => this.setRemBaseValue(value));
    handleEvent('textSizeUnit', (value) => this.setTextSizeUnit(value));
    handleEvent('setNotification', (data) => this.setNotification(data));
    handleEvent('setFigmaFileName', (name) => this.setFigmaFileName(name));
    handleEvent('cssClassPrefix', (value) => this.setCssClassPrefix(value));
    handleEvent('variableNumberUnit', (value) => this.setVariableNumberUnit(value));
    handleEvent('textStyleClassPrefix', (value) => this.setTextStyleClassPrefix(value));
    handleEvent('effectStyleClassPrefix', (value) => this.setEffectStyleClassPrefix(value));
    handleEvent('showNotificationsNumber', (value) => this.setShowNotificationsNumber(value));
    handleEvent('isFirstTimeOnPlugin', (value) => this.setIsFirstTimeOnPlugin(value === 'true'));
    handleEvent('hasReadDesignSystemNotification', (value) =>
      this.setHasReadDesignSystemNotification(value === 'true')
    );

    console.log(`@figma-to-webflow/figma-app: v${__VERSION__} ✅`);
  },
  computed: {
    ...mapState(['currentPage', 'isLoggedIn', 'isSafari', 'selectedSiteId']),
  },
  methods: {
    ...mapMutations([
      'setNotification',
      'setShowNotificationsNumber',
      'setCssClassPrefix',
      'setTextSizeUnit',
      'setWarning',
      'setIsFirstTimeOnPlugin',
      'setFigmaFileName',
      'setHasReadDesignSystemNotification',
      'setVariableNumberUnit',
      'setRemBaseValue',
      'setTextStyleClassPrefix',
      'setEffectStyleClassPrefix',
    ]),
    logError(error) {
      // TODO data from sandbox might be limited/unreadable, consider improving the quality of the data (i.e. sourcemaps?)
      log(error, { service: 'figma-to-webflow/figma-sandbox' });
    },
  },
};
</script>
