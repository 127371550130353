<template>
  <div class="wf-onboarding">
    <WfOnboardingScreen
      v-if="step === 0"
      image="/error.png"
      :title="localErrorTitle"
      :description="localErrorMessage"
      :has-prev="false"
      :has-next="false"
      :step="step"
      :has-get-started="false"
      :has-start="false"
      :has-error="true"
      @next-clicked="step++"
      @previous-clicked="step--"
      @try-again="handleTryAgainClick()"
    />
    <WfOnboardingScreen
      v-if="step === 1"
      image="/auto-layout.png"
      title="Sync layers, variables, and styles"
      description="Turn your static designs into clean, production-ready HTML and CSS with Webflow. Sync your design system to give your team the power to launch sophisticated sites quickly."
      :has-prev="false"
      :has-next="true"
      :step="step"
      :has-get-started="false"
      :has-start="false"
      :has-error="false"
      @next-clicked="step++"
      @previous-clicked="step--"
    />
    <WfOnboardingScreen
      v-if="step === 2"
      image="/connect.png"
      title="Connect to Webflow"
      description="Log in with Webflow and choose the Workspaces or sites you want to sync to."
      :has-prev="true"
      :has-next="false"
      :step="step"
      :has-get-started="true"
      :has-start="false"
      :has-error="false"
      :is-loading="isLoading"
      @next-clicked="step++"
      @previous-clicked="step--"
      @get-started="connectoToWebflowAccount()"
    />
    <WfOnboardingScreen
      v-if="step === 3"
      image="/success.png"
      title="Connect to Webflow"
      description="Once you click “Authorize App,” you’re ready to start syncing your designs from Figma to Webflow."
      :has-prev="false"
      :has-next="false"
      :step="step"
      :has-get-started="false"
      :has-start="true"
      :has-error="false"
      :is-loading="isLoading"
      @next-clicked="step++"
      @previous-clicked="step--"
      @start="getUserInfo()"
    />
  </div>
</template>

<script>
import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';
import {
  trackEvent,
  trackUser,
  PLUGIN_SIGNIN,
  OAUTH_INITIATED,
  OAUTH_COMPLETED,
  ACCEPTED_TERMS,
} from '@/helpers/analytics';
import { dispatch } from '../figma/ui-message-handler';
import { mapState, mapMutations, mapActions } from 'vuex';
import { isAuthError } from '@/helpers/errors';

import WfOnboardingScreen from '@/components/ui/WfOnboardingScreen.vue';
import { mapGetters } from 'vuex/dist/vuex.cjs.js';

export default {
  components: {
    WfOnboardingScreen,
  },
  data() {
    return {
      key: '',
      code: '',

      step: 1,
      readKey: '',
      pollTries: 10,
      isLoading: false,

      hasOptIn: true,

      localErrorTitle: 'No sites found',
      localErrorMessage:
        'It looks like something went wrong during authentication. Please try again.',
    };
  },
  computed: {
    ...mapState(['user', 'userInfo', 'authorizationCode']),
    ...mapGetters(['errorTitle', 'errorMessage']),
  },
  methods: {
    ...mapActions(['generateKeys', 'getAuthenticatedUser', 'deleteKeys']),
    ...mapMutations([
      'setIsLoggedIn',
      'setToken',
      'setRefreshToken',
      'setUser',
      'setUserInfo',
      'setUserSites',
      'setShowNotificationsNumber',
      'setWriteKey',
      'setErrorObject',
    ]),

    async connectoToWebflowAccount() {
      this.isLoading = true;
      let keys;
      try {
        keys = await this.generateKeys();
      } catch (error) {
        log(error);
        errorHandling(error);
        this.showErrorMessage();
        return;
      }

      this.setWriteKey(keys.write);
      this.readKey = keys.read;
      this.getWebflowCode(keys.read);
      trackEvent(OAUTH_INITIATED);
    },
    async getWebflowCode(read) {
      const clientId = import.meta.env.VITE_WEBFLOW_CLIENT_ID;
      const endpoint = import.meta.env.VITE_WF_API_URL;
      const scope = ['assets:write', 'authorized_user:read', 'sites:read'].join(' ');
      const url = `${endpoint}/oauth/authorize?client_id=${clientId}&response_type=code&state=${read}&scope=${scope}`;
      window.open(url, '_blank');

      // Change to Let's start screen after 3 seconds
      setTimeout(() => {
        this.step = 3;
        this.isLoading = false;
      }, 3000);
    },
    async getUserInfo() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append('readKey', this.readKey);
      payload.append('hasOptIn', this.hasOptIn);

      let user;
      try {
        user = await this.getAuthenticatedUser(payload);
      } catch (error) {
        if (!isAuthError(error)) log(error);
        this.showErrorScreen(this.readKey);
        return;
      }

      const userData = user.user;
      const userInfo = user.userInfo;
      const userSites = user.userSites;
      // Check if the user has authorized sites
      // if not, show error screen and return.
      if (!userSites || userSites.length === 0) {
        this.showErrorScreen(this.readKey, false);
        return;
      }

      this.setUser(userData);
      this.setUserInfo(userInfo);
      this.setUserSites(userSites);
      this.identifyUserForSegment(userData);

      const { token, refreshToken } = user;
      dispatch('setClientStorage', { name: 'token', data: { token, refreshToken } });
      this.setToken(token);
      this.setRefreshToken(refreshToken);

      this.isLoading = false;
      this.setIsLoggedIn(true);
      trackEvent(OAUTH_COMPLETED);
      return;
    },
    identifyUserForSegment(user) {
      trackUser(user.id);
      trackEvent(PLUGIN_SIGNIN);
      trackEvent(ACCEPTED_TERMS);
    },
    showErrorMessage() {
      this.localErrorTitle = this.errorTitle;
      this.localErrorMessage = this.errorMessage;
      this.isLoading = false;
      this.step = 0;
    },
    showErrorScreen(read, hasAuthorizedSites = true) {
      if (!hasAuthorizedSites) {
        this.localErrorTitle = 'Link unsuccessful';
        this.localErrorMessage =
          'You must select at least one Webflow site during the authentication process. Please try again.';
      } else {
        const payload = new FormData();
        payload.append('readKey', read);
        this.deleteKeys(payload);
      }
      this.isLoading = false;
      this.step = 0;
    },
    handleTryAgainClick() {
      this.setErrorObject({
        show: false,
        code: null,
        error: null,
      });
      this.step = 1;
    },
  },
};
</script>
