<template>
  <div class="wf-main-content" :class="{ 'wf-main-content--no-footer': !hasFooter }">
    <div class="wrapper">
      <WfErrorMessage v-if="hasError" />
      <template v-else>
        <WfLayersPage v-if="selectedTab === 'layers'" />
        <WfVariablesPage v-else-if="selectedTab === 'variables'" />
        <WfStylesPage v-else-if="selectedTab === 'styles'" />
        <WfImagesPage v-else-if="selectedTab === 'images'" />

        <WfSettingsPage v-else-if="selectedTab === 'settings'" />
        <WfLayoutsPage v-else-if="selectedTab === 'layouts'" />
      </template>
    </div>
    <footer v-if="hasFooter">
      <WfErrorButtons v-if="hasError" />
      <template v-else>
        <WfLayersButton v-if="selectedTab === 'layers'" />
        <WfVariablesButton v-else-if="selectedTab === 'variables'" />
        <WfStylesButton v-else-if="selectedTab === 'styles'" />
        <WfImagesButton v-else-if="selectedTab === 'images'" />
      </template>
    </footer>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import WfLayersPage from './layers/WfLayersPage.vue';
import WfLayersButton from './layers/WfLayersButton.vue';
import WfVariablesPage from './variables/WfVariablesPage.vue';
import WfVariablesButton from './variables/WfVariablesButton.vue';
import WfImagesPage from './images/WfImagesPage.vue';
import WfImagesButton from './images/WfImagesButton.vue';
import WfStylesPage from './styles/WfStylesPage.vue';
import WfStylesButton from './styles/WfStylesButton.vue';

import WfSettingsPage from './settings/WfSettingsPage.vue';
import WfLayoutsPage from './settings/WfLayoutsPage.vue';

import WfErrorMessage from '@/components/errorHandling/WfErrorMessage.vue';
import WfErrorButtons from '@/components/errorHandling/WfErrorButtons.vue';

export default {
  components: {
    WfLayersPage,
    WfImagesPage,
    WfVariablesPage,
    WfStylesPage,
    WfLayersButton,
    WfImagesButton,
    WfVariablesButton,
    WfStylesButton,

    WfSettingsPage,
    WfLayoutsPage,

    WfErrorMessage,
    WfErrorButtons,
  },
  computed: {
    ...mapState(['selectedTab']),
    ...mapGetters(['hasError']),
    hasFooter() {
      return (
        this.selectedTab !== 'settings' &&
        this.selectedTab !== 'layouts' &&
        this.selectedTab !== 'help'
      );
    },
  },
};
</script>
