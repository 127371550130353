<template>
  <div
    class="wf-layer-name"
    :title="layer.layerData.name"
    :class="{ 'is-component': isComponent, 'is-non-auto-layout-frame': isNonAutoLayoutFrame }"
  >
    <WfIcon :icon="iconName" />
    <span>{{ layer.layerData.name }}</span>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';
export default {
  components: {
    WfIcon,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
    isNonAutoLayoutFrame: {
      type: Boolean,
      required: false,
      default: false,
    },
    isImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isComponent() {
      return (
        this.layer.layerData.type === 'COMPONENT' ||
        this.layer.layerData.type === 'INSTANCE' ||
        this.layer.layerData.type === 'COMPONENT_SET'
      );
    },
    iconName() {
      const layerData = this.layer.layerData;
      if (layerData.type === 'FRAME' && !layerData.layoutMode) {
        return 'frame';
      }
      if (layerData.type === 'GROUP') {
        return 'group';
      }
      if (this.isImage) return 'image';
      const nonAutoLayoutTypes = ['COMPONENT', 'INSTANCE', 'TEXT', 'IMAGE', 'COMPONENT_SET'];
      const isNonAutoLayoutType = nonAutoLayoutTypes.includes(layerData.type);
      if (isNonAutoLayoutType) {
        if (layerData.type === 'COMPONENT_SET') {
          return 'component';
        }
        return layerData.type.toLowerCase();
      }

      if (layerData.layoutWrap === 'WRAP') {
        return `wrap-${layerData.layoutAlignment.toLowerCase()}`;
      } else {
        return `${layerData.layoutMode.toLowerCase()}-${layerData.layoutAlignment.toLowerCase()}`;
      }
    },
  },
};
</script>
