<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import { isSafari } from '@/helpers/utils';
import { mapMutations, mapActions } from 'vuex';
import { trackEvent, trackUser, PLUGIN_SIGNIN } from '@/helpers/analytics';
import { log } from '@/helpers/logger';
import { dispatch, handleEvent } from '../figma/ui-message-handler';
import * as authApi from '@/api/auth-api';

export default {
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    dispatch('getClientStorage', 'token');
    handleEvent('clientStorageResponse', (token) => this.handleAuthentication(token));
  },
  methods: {
    ...mapActions(['getUserData']),
    ...mapMutations([
      'setToken',
      'setRefreshToken',
      'setIsLoggedIn',
      'setUser',
      'setUserInfo',
      'setUserSites',
      'setIsSafari',
    ]),

    async handleAuthentication(credentials) {
      if (isSafari) {
        return this.setIsSafari(true);
      }

      // Check for old write key
      if (typeof credentials === 'string') {
        try {
          const response = await authApi.getToken(credentials);
          credentials = response.data;
        } catch (error) {
          credentials = null;
        }
      }

      if (!credentials) {
        this.setIsLoggedIn(false);
        return this.$emit('set-is-global-loading', false);
      }

      const { token, refreshToken } = credentials;
      this.setToken(token);
      this.setRefreshToken(refreshToken);

      // Get user info and log in.
      try {
        const userData = await this.setUserData();
        if (userData != null) {
          this.setIsLoggedIn(true);
        }
      } catch (error) {
        log(error);
        this.logUserOut();
      }

      this.$emit('set-is-global-loading', false);
    },
    async setUserData() {
      let user;
      try {
        user = await this.getUserData();
      } catch (error) {
        log(error);
        this.logUserOut();
        return null;
      }

      const userData = user?.user;
      const userInfo = user?.userInfo;
      const userSites = user?.userSites;

      if (!userData || !userInfo || !userSites) {
        this.logUserOut();
        return null;
      }
      this.setUser(userData);
      this.setUserInfo(userInfo);
      this.setUserSites(userSites);
      this.identifyUserForSegment(userData);
      return userData;
    },

    logUserOut() {
      dispatch('setClientStorage', { name: 'token', data: null });

      this.setUser(null);
      this.setToken(null);
      this.setRefreshToken(null);
      this.setUserInfo(null);
      this.setIsLoggedIn(false);

      this.$emit('set-is-global-loading', false);
    },
    identifyUserForSegment(user) {
      trackUser(user.id);
      trackEvent(PLUGIN_SIGNIN);
    },
  },
};
</script>
