<template>
  <div class="wf-table-group">
    <div
      v-if="!isRootGroup && (hasStyles || hasStyleGroups)"
      class="wf-table-row wf-table-row--group"
      :style="{ paddingLeft: `${depth * depthValue}px` }"
    >
      <div class="wf-group-content" @click="handleSetIsSelectedGroup">
        <WfCheckbox :status="selectionStatus" />
        {{ group.name }}
      </div>
    </div>
    <template v-if="hasStyles">
      <WfStyleRow
        v-for="style in group.styles"
        :style="style"
        :key="style.id"
        :is-root="isRootGroup"
        :depth="isRootGroup ? depth : depth + 1"
      />
    </template>
    <template v-if="hasStyleGroups">
      <WfStyleGroupsRow v-for="g in group.styleGroups" :key="g.id" :group="g" :depth="depth + 1" />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getGroupSelectionStatus, toggleRootGroup } from '@/helpers/styles';
import WfStyleRow from './WfStyleRow.vue';
import WfCheckbox from '@/components/mainContent/WfCheckbox.vue';
export default {
  name: 'WfStyleGroupsRow',
  components: {
    WfCheckbox,
    WfStyleRow,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(['selectedStylesGroups', 'depthValue']),
    isRootGroup() {
      return this.group.name === 'Root';
    },
    hasStyles() {
      return this.group.styles?.length;
    },
    hasStyleGroups() {
      return this.group.styleGroups?.length;
    },
    selectionStatus() {
      return getGroupSelectionStatus(this.group);
    },
  },
  methods: {
    handleSetIsSelectedGroup() {
      const isSelected = this.selectionStatus === 'selected' ? false : true;
      toggleRootGroup(this.group.id, isSelected);
      if (this.group.styleGroups) {
        this.toggleAllChildrenGroupsRecursively(this.group.styleGroups, isSelected);
      }
    },
    toggleAllChildrenGroupsRecursively(children, isSelected) {
      for (const child of children) {
        toggleRootGroup(child.id, isSelected);
        if (child.styleGroups) {
          this.toggleAllChildrenGroupsRecursively(child.styleGroups, isSelected);
        }
      }
    },
  },
};
</script>
