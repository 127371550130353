<template>
  <div class="wf-image-extension-dropdown">
    <WfDropdown
      :options="dropdownOptions"
      :selected-option="selectedOption"
      @selected="handleSelected"
      direction="left"
    >
      <span>{{ selectedOption.toUpperCase() }}</span>
      <div class="icon-wrapper">
        <WfIcon icon="caret-down" />
      </div>
    </WfDropdown>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import WfDropdown from '@/components/mainContent/dropdown/WfDropdown.vue';
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfDropdown,
    WfIcon,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOptions: [
        { id: 'png', name: 'PNG' },
        { id: 'jpg', name: 'JPG' },
        { id: 'svg', name: 'SVG' },
      ],
    };
  },
  computed: {
    selectedOption() {
      return this.image.type;
    },
  },
  methods: {
    ...mapMutations(['setNewPropValueImage']),
    handleSelected(option) {
      this.setNewPropValueImage(this.image.id, 'type', option.id);
    },
  },
};
</script>
