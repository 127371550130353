import store from '@/store';
export const getSelectedCollectionDetails = (collection) => {
  return {
    collectionId: collection.id,
    collectionKey: collection.key,
    local: collection.local,
  };
};
export const populateSelectedVariables = (collection) => {
  // Helper function to merge two arrays based on a comparison function
  const mergeSelectedVariables = (selectedVariables, selectedVariablesMap) => {
    // Add missing variables from selectedVariablesMap to selectedVariables
    const addedVariables = selectedVariablesMap.filter(
      (variable) => !selectedVariables.some((v) => v.id === variable.id)
    );

    // Remove variables from selectedVariables that are not in selectedVariablesMap
    const filteredSelectedVariables = selectedVariables.filter((variable) =>
      selectedVariablesMap.some((v) => v.id === variable.id)
    );

    // Combine both added and filtered selected variables
    return [...filteredSelectedVariables, ...addedVariables];
  };

  // get all variables from the collection, recursively
  const getSelectedVariablesMap = (collection) => {
    const variableGroups = getGroups([collection]);
    return variableGroups.flatMap((group) =>
      group.variables.map((v) => ({
        id: v.id,
        groupId: group.id,
        name: v.name,
        wfVariableUnit: v.wfVariableUnit,
        modes: v.modes,
        isSelected: true,
      }))
    );
  };

  // Main function to update the selected variables
  const updateSelectedVariables = (collection, selectedVariables) => {
    const selectedVariablesMap = getSelectedVariablesMap(collection); // from the collection

    // Make sure the selectedVariables from the store have the same values as the selectedVariablesMap
    const updatedSelectedVariablesFromStore = selectedVariables.map((v) => {
      const variable = selectedVariablesMap.find((v2) => v2.id === v.id);
      return { ...variable, ...v };
    });

    // Create the updated list of selected variables using the merge function
    const updatedSelectedVariables = mergeSelectedVariables(
      updatedSelectedVariablesFromStore, // what is in the store
      selectedVariablesMap
    );

    return updatedSelectedVariables;
  };

  const updatedVariables = updateSelectedVariables(collection, store.state.selectedVariablesMap);
  // Commit the updated selected variables back to the store
  store.commit('setSelectedVariablesMap', updatedVariables);
};
export const populateSelectedVariableGroups = (collection) => {
  const mergeSelectedVariables = (selectedVariableGroups, selectedGroupsMap) => {
    const addedGroups = selectedGroupsMap.filter(
      (group) => !selectedVariableGroups.some((v) => v.id === group.id)
    );
    const filteredSelectedGroups = selectedVariableGroups.filter((group) =>
      selectedGroupsMap.some((v) => v.id === group.id)
    );

    // Combine both added and filtered selected Groups
    return [...filteredSelectedGroups, ...addedGroups];
  };
  const getSelectedGroupsMap = (collection) => {
    const variableGroups = getGroups([collection]);
    return variableGroups.map((group) => ({ id: group.id, isSelected: true }));
  };
  const updateSelectedVariableGroups = (collection, selectedVariableGroups) => {
    const selectedGroupsMap = getSelectedGroupsMap(collection);
    const updatedSelectedGroups = mergeSelectedVariables(selectedVariableGroups, selectedGroupsMap);
    return updatedSelectedGroups;
  };
  const updatedSelectedVariableGroups = updateSelectedVariableGroups(
    collection,
    store.state.selectedVariableGroups
  );
  store.commit('setSelectedVariableGroups', updatedSelectedVariableGroups);
};
export const toggleSelectedItem = (itemId, itemType) => {
  if (itemType === 'group') {
    // set all variables to true or false based on the group selection status
    const group = store.state.selectedVariableGroups.find((g) => g.id === itemId);
    const updatedVariables = store.state.selectedVariablesMap.map((v) =>
      v.groupId === itemId ? { ...v, isSelected: !group.isSelected } : v
    );
    store.commit('setSelectedVariablesMap', updatedVariables);
  } else if (itemType === 'variable') {
    // if all variables from their group are selected, update isSelected to true
    // if all variables from their group are unselected, update isSelected to false
    const { state } = store;
    const variable = state.selectedVariablesMap.find((v) => v.id === itemId);
    const group = state.selectedVariableGroups.find((g) => g.id === variable.groupId);

    const allVariablesFromGroup = state.selectedVariablesMap.filter(
      (v) => v.groupId === variable.groupId
    );
    const allVariablesStatus = getAllVariablesStatus(allVariablesFromGroup);

    if (allVariablesStatus !== 'mixed') {
      const updatedGroups = updateGroupSelection(
        state.selectedVariableGroups,
        group.id,
        allVariablesStatus === 'selected'
      );
      store.commit('setSelectedVariableGroups', updatedGroups);
    }
  }
  toggleItem(itemId, itemType);
};
const toggleItem = (itemId, itemType) => {
  // Find the item in the store
  const array =
    store.state[itemType === 'group' ? 'selectedVariableGroups' : 'selectedVariablesMap'];
  const item = array.find((item) => item.id === itemId);
  // Toggle the isSelected property
  item.isSelected = !item.isSelected;
  // Commit the updated item back to the store
  store.commit(
    `${itemType === 'group' ? 'setSelectedVariableGroups' : 'setSelectedVariablesMap'}`,
    array
  );
};

const convertValue = (value, fromUnit, toUnit, remBaseValue) => {
  if (fromUnit === toUnit) return value;

  const valuePx = fromUnit === 'rem' || fromUnit === 'em' ? value * remBaseValue : value;

  return toUnit === 'rem' || toUnit === 'em' ? valuePx / remBaseValue : valuePx;
};

export const updateVariableUnitInSelectedVariablesMap = (
  variableId,
  newUnit,
  remBaseValue,
  selectedMode
) => {
  const variable = store.state.selectedVariablesMap.find((v) => v.id === variableId);

  // Update all modes values
  variable.modes = variable.modes.map((mode) => {
    const currentValue = mode.value.value ?? mode.value;
    const currentUnit = variable.wfVariableUnit ?? mode.value.unit ?? 'px';
    const convertedValue = convertValue(currentValue, currentUnit, newUnit, remBaseValue);

    return {
      ...mode,
      value: {
        unit: newUnit,
        value: convertedValue,
      },
    };
  });

  variable.wfVariableUnit = newUnit;
  store.commit('setSelectedVariablesMap', store.state.selectedVariablesMap);
  const convertedValue = variable.modes.find((mode) => mode.modeId === selectedMode.id).value.value;
  return convertedValue;
};

// Helper functions
export const hasEmptyVariables = (group) => {
  if (!group?.length) {
    return true;
  }

  // Check if the current group has an empty variables array
  if (group.variables && group.variables.length === 0) {
    return true;
  }

  // If no empty variables found, recursively check any nested variableGroups
  if (group.variableGroups) {
    for (const subGroup of group.variableGroups) {
      if (hasEmptyVariables(subGroup)) {
        return true;
      }
    }
  }

  // If no empty variables found in this group or any sub-groups, return false
  return false;
};

export const getSelectedVariablesPayload = (selectedVariables, selectedMode) => {
  return selectedVariables.map((variable) => {
    const mode = variable.modes.find((mode) => mode.modeId === selectedMode.id);
    if (!mode) {
      return;
    }

    return {
      id: variable.id,
      name: variable.name,
      type: mode.type,
      value: mode.value,
    };
  });
};
export const getGroupSelectionStatus = (group) => {
  const { state } = store;
  const allVariablesFromGroup = state.selectedVariablesMap.filter((v) => v.groupId === group.id);
  return getAllVariablesStatus(allVariablesFromGroup);
};
export const getAllVariablesStatus = (variables) => {
  const allSelected = variables.every((v) => v.isSelected);
  const allUnselected = variables.every((v) => !v.isSelected);
  return allSelected ? 'selected' : allUnselected ? 'unselected' : 'mixed';
};
const updateGroupSelection = (groups, groupId, isSelected) =>
  groups.map((g) => (g.id === groupId ? { ...g, isSelected } : g));

const getGroups = (collections) => {
  const extractGroups = (group, collectionId) => {
    const currentGroup = {
      id: group.id,
      collectionId: collectionId,
      name: group.name,
      isRoot: group.name === 'Root',
      variables: group?.variables ?? [],
      variableGroups: group?.variableGroups ?? [],
      isSelected: group?.isSelected ?? true,
    };

    const nestedGroups = group.variableGroups
      ? group.variableGroups.flatMap((g) => extractGroups(g, collectionId))
      : [];

    return [currentGroup, ...nestedGroups];
  };

  return collections.flatMap((collection) => {
    return collection.variableGroups.flatMap((g) => extractGroups(g, collection.id));
  });
};

export const selectOrDeselectAllVariables = (goal) => {
  const updatedVariables = store.state.selectedVariablesMap.map((v) => ({
    ...v,
    isSelected: goal === 'select' ? true : false,
  }));
  store.commit('setSelectedVariablesMap', updatedVariables);
};
