<template>
  <div
    class="wf-table-row"
    :class="{ 'is-root': isRoot }"
    :style="{ paddingLeft: `${depth * depthValue}px` }"
  >
    <div class="wf-table-cell" @click="handleCheckboxChange">
      <WfCheckbox :status="isSelected ? 'selected' : 'unselected'" />
      <WfStyleRowName :style="style" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { toggleSelectedItem } from '@/helpers/styles';
import WfStyleRowName from './WfStyleRowName.vue';
import WfCheckbox from '@/components/mainContent/WfCheckbox.vue';
export default {
  components: {
    WfCheckbox,
    WfStyleRowName,
  },
  props: {
    style: {
      type: Object,
      required: true,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(['selectedStylesMap', 'depthValue']),
    isSelected() {
      return this.selectedStylesMap.find((s) => s.id === this.style.id)?.isSelected ?? true;
    },
  },
  methods: {
    handleCheckboxChange() {
      toggleSelectedItem(this.style.id, 'style');
    },
  },
};
</script>
