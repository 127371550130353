import axios from 'axios';
import { getAuthHeader } from './headers';
import {
  requestInterceptor,
  errorResponseInterceptor,
} from '@/helpers/api/refreshTokenInterceptors';

export const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.request.use((config) => requestInterceptor(config));

instance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseInterceptor(error)
);

export const generateKeys = async (data) => {
  const URL = `generate-keys`;
  return await instance.post(URL, data);
};

export const getAuthenticatedUser = async (data) => {
  const URL = `get-authenticated-user`;
  return await instance.post(URL, data);
};

export const getUserData = async () => {
  const URL = `get-user-data`;
  return await instance.post(URL, {}, { headers: getAuthHeader() });
};

// Migrates old write keys to new JWT tokens
export const getToken = (writeKey) => {
  const URL = `token`;

  return instance.get(URL, {
    headers: { Authorization: `Bearer ${writeKey}` },
  });
};

export const logUserOut = async () => {
  const URL = `log-user-out`;
  return await instance.post(URL, {}, { headers: getAuthHeader() });
};

export const deleteKeys = async (data) => {
  const URL = `delete-keys`;
  return await instance.post(URL, data);
};

export const storePreBuiltComponentJson = async (data) => {
  const URL = `store-pre-built-components`;
  return await instance.post(URL, data);
};
